import React from 'react';
import {CommonEdit} from '../Common';
import {getInputs} from './Common';

export const OrganizationEdit = props => (
  <CommonEdit
    {...props}
    fields={[
      {
        source: 'id',
        disabled: true,
      },
      ...getInputs(),
    ]}
  />
);
