import React from 'react';
import {CommonList} from '../Common';
import {nameFilter} from '../Filters';

export const MaterialFamilyList = props => (
  <CommonList
    {...{
      ...props,
      edit: true,
      fields: [
        {
          source: 'code',
        },
        {
          source: 'name',
        },
      ],
      filters: [nameFilter],
      title: props.options.label,
    }}
  />
);
