import {AbstractBCMProvider} from './abstract';
import {MaterialFamily as MaterialFamilyModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {MaterialFamilySerializer} from '../serializer';

export class MaterialFamily extends AbstractBCMProvider {
  endpoint = PROVIDER.MATERIAL_FAMILIES;
  management = MANAGEMENT.MATERIAL;
  model = new MaterialFamilyModel();
  name = PROVIDER.MATERIALS;
  serializer = new MaterialFamilySerializer();

  constructor(props) {
    super(props);
    if (this.filter?.name) {
      this.filter = {...this.filter, materialFamilyName: this.filter.name};
    }
  }
}
