export class PeriodSerializer {
  serialize(obj, data) {
    if (data?.end) {
      obj.validityPeriodEndDate = new Date(data.end);
    } else {
      obj.validityPeriodEndDate = null;
    }
    if (data?.start) {
      obj.validityPeriodStartDate = new Date(data.start);
    } else {
      obj.validityPeriodStartDate = null;
    }
  }
}
