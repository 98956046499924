import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class ExchangeRateSerializer extends BCMSerializer {
  base = PROVIDER.EXCHANGE_RATES;

  serialize(exchangeRate) {
    this.idExchangeRate = exchangeRate.id;
    this.fromCurrencyCode = exchangeRate.currencies.from;
    this.toCurrencyCode = exchangeRate.currencies.to;
    const period = exchangeRate.period.split('-');
    this.exchangeRateYear = period[0];
    this.exchangeRateMonth = period[1];
    this.exchangeRateValue = exchangeRate.value;
  }
}
