import {Model, ModelInstance} from './Common';

export class MaterialFamilyInstance extends ModelInstance {
  code = '';

  constructor(materialFamily) {
    super(materialFamily);
    this.code = materialFamily.materialFamilyCode;
    this.id = materialFamily.idMaterialFamily;
    this.name = materialFamily.materialFamilyName;
  }
}

export class MaterialFamily extends Model {
  getMany = materialFamilies =>
    materialFamilies.map(materialFamily => new MaterialFamilyInstance(materialFamily));
  getOne = materialFamily => new MaterialFamilyInstance(materialFamily);
}
