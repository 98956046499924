import {Model, ModelInstance} from './Common';
import {MaterialFamily} from './MaterialFamily';

export class MaterialSubFamilyInstance extends ModelInstance {
  code = '';
  family = null;

  constructor(materialSubFamily) {
    super(materialSubFamily);
    this.code = materialSubFamily.materialSubFamilyCode;
    this.id = materialSubFamily.idMaterialSubFamily;
    this.name = materialSubFamily.materialSubFamilyName;
    this.family =
      materialSubFamily.materialFamily &&
      new MaterialFamily().getOne(materialSubFamily.materialFamily);
  }
}

export class MaterialSubFamily extends Model {
  getMany = materialSubFamilys =>
    materialSubFamilys.map(
      materialSubFamily => new MaterialSubFamilyInstance(materialSubFamily)
    );
  getOne = materialSubFamily => new MaterialSubFamilyInstance(materialSubFamily);
}
