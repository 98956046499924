import React from 'react';
import {CommonList} from '../Common';
import {getFields} from './Common';

export const MaterialIndexValuesList = props => (
  <CommonList
    {...{
      ...props,
      edit: true,
      fields: getFields(),
      title: props.options.label,
    }}
  />
);
