import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';
import {PeriodSerializer} from './PeriodSerializer';

export class ContractSerializer extends BCMSerializer {
  base = PROVIDER.MATERIALS;
  contractName = '';
  description = '';
  validityPeriodStartDate = new Date();
  validityPeriodEndDate = new Date();
  localITSystemContractUID = '';
  idCompany = '';

  serialize(data) {
    this.contractName = data.name;
    this.description = data.description;
    new PeriodSerializer().serialize(this, data.period);
    this.localITSystemContractUID = data.localContractUid;
    this.idCompany = data.company?.id || data.company;
  }
}
