import React from 'react';
import {Datagrid, EditButton, List, ShowButton} from 'react-admin';
import {Filters} from '../Filters';
import {FieldGuesser} from './FieldGuesser';
import {AppInsightProvider} from '../../contexts';
import {CustomPagination} from './Overrides/Pagination';

export const CommonList = ({children, edit, fields, filters = [], show, ...rest}) => (
  <List
    {...{
      ...rest,
      className: 'p-2',
      exporter: false,
      filters: <Filters filters={filters} />,
      pagination: <CustomPagination />,
      title: rest.options.label,
    }}
  >
    <AppInsightProvider>
      {children ? (
        children
      ) : (
        <Datagrid>
          {fields.map((field, key) => (
            <FieldGuesser key={key} {...{...field, sortable: false}} />
          ))}
          {show && <ShowButton />}
          {edit && <EditButton />}
        </Datagrid>
      )}
    </AppInsightProvider>
  </List>
);
