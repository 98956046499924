import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class MaterialTCO2Serializer extends BCMSerializer {
  base = PROVIDER.MATERIAL_TCO2;
  idMaterialSubFamily = '';
  year = '';
  teqCO2 = '';

  serialize(materialTCO2) {
    this.idMaterialSubFamily = materialTCO2.subFamily?.id || materialTCO2.subFamily;
    this.year = materialTCO2.year;
    this.teqCO2 = materialTCO2.teqCO2;
  }
}
