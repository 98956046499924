import {required} from 'react-admin';
import {REFERENCE_ARRAY_INPUT, REFERENCE_INPUT} from '../Common';
import {PROVIDER} from '../../resources/bcmApi';

export const getInputs = () => [
  {
    source: 'name',
    validate: [required('The material referential name is mandatory')],
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Material referential type',
    reference: PROVIDER.MATERIAL_REFERENTIAL_TYPES,
    source: 'materialReferentialTypeResponse.id',
    type: REFERENCE_INPUT,
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Referenced referentials',
    reference: PROVIDER.MATERIAL_REFERENTIALS,
    source: 'referentialsIds',
    target: 'referentialsIds',
    type: REFERENCE_ARRAY_INPUT,
  },
  /*{
    children: {
      optionText: 'name',
    },
    label: 'Companies',
    reference: PROVIDER.COMPANIES,
    source: 'companiesIds',
    target: 'id',
    type: AUTOCOMPLETE_ARRAY_INPUT,
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Organizations',
    reference: PROVIDER.ORGANIZATIONS,
    source: 'organizationsIds',
    target: 'id',
    type: REFERENCE_ARRAY_INPUT,
  },
  /*{
    children: {
      optionText: 'name',
    },
    label: 'Materials',
    reference: PROVIDER.MATERIALS,
    source: PROVIDER.MATERIALS,
    target: PROVIDER.MATERIALS,
    type: REFERENCE_ARRAY_INPUT,
  },*/
];
