import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';
import {theme} from '../../theme';

export const Loader = () => (
  <MuiThemeProvider {...{theme}}>
    <CircularProgress color="secondary" size={20} />
  </MuiThemeProvider>
);
