import {AUTOCOMPLETE_INPUT, DATETIME, FUNCTION_FIELD, REFERENCE_FIELD} from '../Common';
import {PROVIDER} from '../../resources/bcmApi';
import {required} from 'react-admin';

export const getFields = () => [
  {source: 'name'},
  {source: 'description'},
  {
    label: 'Local uid',
    source: 'localContractUid',
  },
  {
    children: {source: 'fullname'},
    label: 'Company',
    link: 'show',
    reference: PROVIDER.COMPANIES,
    source: 'company.id',
    type: REFERENCE_FIELD,
  },
  {
    label: 'Period',
    render: record => {
      const parts = [];
      if (record.period.start) {
        parts.push(new Date(record.period.start).toLocaleDateString());
      }
      if (record.period.end) {
        parts.push(' - ');
        parts.push(new Date(record.period.end).toLocaleDateString());
      }
      return parts.join('');
    },
    type: FUNCTION_FIELD,
  },
];

export const getInputs = () => [
  {source: 'name'},
  {source: 'description'},
  {
    label: 'Local uid',
    source: 'localContractUid',
  },
  {
    children: {
      optionText: 'fullname',
    },
    label: 'Company',
    reference: PROVIDER.COMPANIES,
    source: 'company.id',
    type: AUTOCOMPLETE_INPUT,
    validate: [required()],
  },
  {
    defaultValue: new Date().toISOString().split('T')[0],
    label: 'Start date',
    source: 'period.start',
    type: DATETIME,
  },
  {
    defaultValue: new Date().toISOString().split('T')[0],
    label: 'End date',
    source: 'period.end',
    type: DATETIME,
  },
];
