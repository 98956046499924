import {
  FieldGuesser,
  DYNAMIC_FIELDS,
  FORMDATA,
  REFERENCE_ARRAY_FIELD,
  REFERENCE_ARRAY_INPUT,
  REFERENCE_FIELD,
  REFERENCE_INPUT,
} from '../Common';
import {
  SimpleShowLayout,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  required,
} from 'react-admin';
import {PROVIDER} from '../../resources/bcmApi';
import {MaterialReferential} from '../../dataProviders/bcm/providers';
import React, {useEffect, useState} from 'react';

const ReferencedMaterialsField = ({record, children, ...rest}) => {
  return (
    <SimpleShowLayout {...{record, ...rest}}>
      {record.referentials.map((r, key) =>
        FieldGuesser({
          key,
          label: `Referenced materials for referential ${r.name}`,
          source: `materialsIds[${r.id}]`,
          reference: PROVIDER.MATERIALS,
          target: 'id',
          type: REFERENCE_ARRAY_FIELD,
          ...children,
        })
      )}
    </SimpleShowLayout>
  );
};

export const getFields = () => [
  {source: 'id'},
  {
    label: 'BCM local country code',
    source: 'countryCode',
  },
  {
    label: 'Material code',
    source: 'code',
  },
  {
    label: 'Material name',
    source: 'name',
  },
  {
    source: 'description',
  },
  {
    children: {source: 'name'},
    label: 'Material family',
    link: 'show',
    reference: PROVIDER.MATERIAL_FAMILIES,
    source: 'family.id',
    type: REFERENCE_FIELD,
  },
  {
    children: {source: 'name'},
    label: 'Material sub family',
    link: 'show',
    reference: PROVIDER.MATERIAL_SUB_FAMILIES,
    source: 'subFamily.id',
    type: REFERENCE_FIELD,
  },
  {
    label: 'Country legal code',
    source: 'localCode',
  },
  {
    label: 'Country legal name',
    source: 'localName',
  },
  {
    label: 'Default unit',
    source: 'unit.name',
  },
  {
    children: {source: 'name'},
    label: 'Material Referential',
    link: 'show',
    reference: PROVIDER.MATERIAL_REFERENTIALS,
    source: 'referential.id',
    type: REFERENCE_FIELD,
  },
  {
    children: {children: {source: 'fullname'}},
    renderChildren: ReferencedMaterialsField,
    type: DYNAMIC_FIELDS,
  },
];

const ReferencedMaterials = ({referential}) => {
  const [referentials, setReferentials] = useState([]);
  useEffect(() => {
    referential &&
      referential.id &&
      new MaterialReferential()
        .getOne({id: referential.id})
        .then(({data: {referentials}}) => setReferentials(referentials));
  }, [referential]);

  return (
    <>
      {referentials.map((r, key) => (
        <ReferenceArrayInput
          {...{
            filter: {referential: r.id},
            label: `Referenced materials for referential ${r.name}`,
            source: `materialsIds[${r.id}]`,
            reference: PROVIDER.MATERIALS,
            target: 'id',
            type: FORMDATA,
          }}
          key={key}
          options={{fullWidthInput: true}}
        >
          <AutocompleteArrayInput {...{optionText: 'fullname'}} />
        </ReferenceArrayInput>
      ))}
    </>
  );
};

const OptionRenderer = item => (
  <div className="material__photo">
    <div className="name" alt={item?.id}>
      {item?.id}
    </div>
    <img src={item?.url} />
  </div>
);

export const getInputs = () => [
  {
    label: 'Material code',
    source: 'code',
  },
  {
    label: 'Material name',
    source: 'name',
    validate: [required('The material name is mandatory')],
  },
  {
    source: 'description',
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Material family',
    source: 'family.id',
    reference: PROVIDER.MATERIAL_FAMILIES,
    target: PROVIDER.MATERIAL_FAMILIES,
    type: REFERENCE_INPUT,
  },
  {
    children: {
      children: {
        optionText: 'name',
      },
      type: REFERENCE_INPUT,
    },
    filter: d => ({idMaterialFamily: (d && d.family?.id) || undefined}),
    label: 'Material sub family',
    source: 'subFamily.id',
    reference: PROVIDER.MATERIAL_SUB_FAMILIES,
    target: PROVIDER.MATERIAL_SUB_FAMILIES,
    type: FORMDATA,
  },
  {
    inputProps: {maxLength: 2},
    source: 'countryCode',
    validate: [required('The country code is mandatory')],
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Default unit',
    source: 'unit.id',
    reference: PROVIDER.MATERIAL_UNITS,
    target: PROVIDER.MATERIAL_UNITS,
    type: REFERENCE_INPUT,
    validate: [required('The default unit is mandatory')],
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Material referential',
    source: 'referential.id',
    reference: PROVIDER.MATERIAL_REFERENTIALS,
    target: PROVIDER.MATERIAL_REFERENTIALS,
    type: REFERENCE_INPUT,
    validate: [required('The material referential is mandatory')],
  },
  {
    children: {
      optionText: OptionRenderer,
    },
    label: 'Photos',
    source: 'photo',
    reference: PROVIDER.IMAGES,
    target: 'photo',
    type: REFERENCE_ARRAY_INPUT,
  },
  {
    renderFormData: ReferencedMaterials,
    type: FORMDATA,
  },
];
