import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';
import {PeriodSerializer} from './PeriodSerializer';

export class ContractIndexValueSerializer extends BCMSerializer {
  base = PROVIDER.MATERIALS;
  materialValueIndexOffset = 0;
  validityPeriodStartDate = new Date();
  validityPeriodEndDate = new Date();
  idContract = '';
  idMaterial = '';
  idMaterialIndexSource = '';

  serialize(data) {
    this.materialValueIndexOffset = data.offset;
    new PeriodSerializer().serialize(this, data.period);
    this.idContract = data.contract?.id || data.contract;
    this.idMaterial = data.material?.id || data.material;
    this.idMaterialIndexSource = data.materialIndexSource?.id || data.materialIndexSource;
  }
}
