import React from 'react';
import {CommonShow} from '../Common';
import {showFields} from './Common';

export const CustomerDistributionShow = props => {
  return (
    <CommonShow
      {...{
        ...props,
        fields: showFields(),
      }}
      title={`Industry domain distribution #${props?.id}`}
    />
  );
};
