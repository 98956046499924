import React from 'react';
import {CommonList} from '../Common/List';
import {getListFields} from './Common';
import {autoCompleteFilter, booleanFilter, inputFilter, nameFilter} from '../Filters';
import {PROVIDER} from '../../resources';
import {withStyles} from '@material-ui/core/styles';

export const ContactList = withStyles({autocompleteInput: {minWidth: 400}})(props => (
  <CommonList
    {...props}
    fields={getListFields()}
    filters={[
      nameFilter,
      key =>
        inputFilter({
          key,
          source: 'email',
        }),
      key =>
        booleanFilter({
          choices: [
            {name: 'Active', id: true},
            {name: 'Inactive', id: false},
          ],
          key,
          source: 'active',
        }),
      key =>
        autoCompleteFilter({
          alwaysOn: true,
          children: {
            optionText: 'name',
          },
          classes: props.classes.autocompleteInput,
          key,
          label: 'Company',
          name: PROVIDER.COMPANIES,
          reference: PROVIDER.COMPANIES,
          source: 'company.id',
        }),
    ]}
    show
    bulkActionButtons={false}
  />
));
