import {AbstractAPIPlatformProvider} from './abstract';
import {User as UserModel} from '../model';
import {PROVIDER} from '../../../resources/bcmApi';
import {UserSerializer} from '../serializer';

export class User extends AbstractAPIPlatformProvider {
  name = PROVIDER.USERS;
  model = new UserModel();
  serializer = new UserSerializer();
}
