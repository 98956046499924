import React from 'react';
import {Create, SimpleForm} from 'react-admin';
import {InputGuesser} from './InputGuesser';
import {AppInsightProvider} from '../../contexts';

export const CommonCreate = ({fields = [], ...rest}) => (
  <Create
    {...{
      ...rest,
      className: 'p-2',
      mutationMode: 'pessimistic',
    }}
  >
    <AppInsightProvider>
      <SimpleForm>
        {fields.map((field, key) => (
          <InputGuesser key={key} {...field} />
        ))}
      </SimpleForm>
    </AppInsightProvider>
  </Create>
);
