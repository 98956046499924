import {AbstractBCMProvider} from './abstract';
import {MaterialIndexSource as MaterialIndexSourceModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {MaterialSerializer} from '../serializer';

export class MaterialIndexSource extends AbstractBCMProvider {
  management = MANAGEMENT.MATERIAL;
  model = new MaterialIndexSourceModel();
  name = PROVIDER.MATERIAL_INDEX_SOURCES;
  serializer = new MaterialSerializer();
}
