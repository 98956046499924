import React from 'react';
import {inputFilter, selectFilter} from '../Filters';
import {CommonList} from '../Common';
import {PROVIDER} from '../../resources';
import {REFERENCE_FIELD} from '../Common/FieldGuesser';

export const MaterialTCO2List = props => (
  <CommonList
    {...{
      ...props,
      bulkActionButtons: false,
      edit: true,
      fields: [
        {
          children: {source: 'name'},
          label: 'Sub family',
          link: 'edit',
          reference: PROVIDER.MATERIAL_SUB_FAMILIES,
          source: 'subFamily.id',
          type: REFERENCE_FIELD,
        },
        {
          source: 'year',
        },
        {
          source: 'teqCO2',
        },
      ],
      filters: [
        key =>
          selectFilter({
            alwaysOn: true,
            key,
            label: 'Sub family',
            source: 'subfamily',
            reference: PROVIDER.MATERIAL_SUB_FAMILIES,
          }),
        key =>
          inputFilter({
            key,
            source: 'year',
          }),
      ],
      title: props.options.label,
      show: true,
    }}
  />
);
