import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class MaterialReferentialSerializer extends BCMSerializer {
  base = PROVIDER.MATERIAL_REFERENTIALS;
  materialReferentialName = '';
  idMaterialReferentialType = '';
  idMaterialReferentials = [];

  serialize(materialReferential) {
    this.materialReferentialName = materialReferential.name;
    this.idMaterialReferentialType =
      materialReferential.materialReferentialTypeResponse.id ||
      materialReferential.materialReferentialTypeResponse;
    this.idMaterialReferentials =
      materialReferential.referentialsIds?.map(c => c.id || c) || [];
  }
}
