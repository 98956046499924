import {AbstractProvider} from '../../Abstract';
import {Token} from '../../../helpers';

export class AbstractAPIPlatformProvider extends AbstractProvider {
  getHeaders = () => {
    const h = {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
    };
    if (new Token().get()) {
      h['Authorization'] = `Bearer ${new Token().get()}`;
    }

    return h;
  };

  getUrl = (value = '') =>
    `${process.env.REACT_APP_API_ENTRYPOINT}/${this.endpoint || this.name}${value}${
      !this.pagination
        ? ''
        : `?${new URLSearchParams({...this.pagination, ...this.filter}).toString()}`
    }`;

  update = ({data}) =>
    this.request()
      .patch(this.getUrl(`/${data.id}`), this.serializer.update(data), {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
      .then(({data}) => ({
        data: this.model.getOne(data),
      }));
}
