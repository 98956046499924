import {AbstractBCMProvider} from './abstract';
import {ExchangeRate as ExchangeRateModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {ExchangeRateSerializer} from '../serializer';

export class ExchangeRate extends AbstractBCMProvider {
  endpoint = PROVIDER.EXCHANGE_RATES;
  management = MANAGEMENT.EXCHANGE_RATE;
  name = PROVIDER.EXCHANGE_RATES;
  model = new ExchangeRateModel();
  serializer = new ExchangeRateSerializer();
}
