import {Model, ModelInstance} from './Common';

export class ExchangeRateInstance extends ModelInstance {
  period = '';
  value = 0;
  currencies = {from: '', to: ''};

  constructor(exchangeRate) {
    super(exchangeRate);
    this.id = exchangeRate.idExchangeRate;
    this.period = new Date(
      Date.UTC(exchangeRate.exchangeRateYear, exchangeRate.exchangeRateMonth - 1)
    ).toLocaleDateString('fr-CA', {year: 'numeric', month: '2-digit'});
    this.currencies = {
      from: exchangeRate.fromCurrencyCode,
      to: exchangeRate.toCurrencyCode,
    };
    this.value = exchangeRate.exchangeRateValue;
  }
}

export class ExchangeRate extends Model {
  getMany = exchangeRates =>
    exchangeRates.map(exchangeRate => new ExchangeRateInstance(exchangeRate));
  getOne = exchangeRate => new ExchangeRateInstance(exchangeRate);
}
