import React, {createContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {ApplicationInsights, SeverityLevel} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

const defaultState = {
  initialized: false,
  appInsights: undefined,
};

export const AppInsightsContext = createContext(defaultState);

export const AppInsightProvider = withRouter(({children, history, ...props}) => {
  const instrumentationKey = process.env.REACT_APP_APP_INSIGHTS || '';

  const [appInsights, setAppInsights] = useState(defaultState.appInsights);
  const [initialized, setInitialized] = useState(defaultState.initialized);
  useEffect(() => {
    let ai = appInsights;
    if (!initialized) {
      const reactPlugin = new ReactPlugin();
      ai = new ApplicationInsights({
        config: {
          instrumentationKey,
          maxBatchInterval: 0,
          disableFetchTracking: false,
          disableTelemetry: false,
          enableUnhandledPromiseRejectionTracking: true,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: {history},
          },
        },
      });

      ai.loadAppInsights();
      setInitialized(true);
    }

    if (!ai) {
      setAppInsights(ai);
    } else {
      ai.trackException({
        error: new Error('some error'),
        severityLevel: SeverityLevel.Error,
      });
    }
  }, [appInsights, history, initialized, instrumentationKey]);
  return (
    <AppInsightsContext.Provider value={{appInsights, initialized}}>
      {React.cloneElement(children, props)}
    </AppInsightsContext.Provider>
  );
});
