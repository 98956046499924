import {AbstractBCMProvider} from './abstract';
import {Site as SiteModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';

export class Site extends AbstractBCMProvider {
  endpoint = PROVIDER.SITES;
  management = MANAGEMENT.COMPANY;
  name = PROVIDER.SITES;
  model = new SiteModel();

  constructor(props) {
    super(props);
    if (this.filter?.company) {
      this.filter = {...this.filter, idCompanies: [this.filter.company.id]};
    }
  }
}
