import {AbstractBCMProvider} from './abstract';
import {CustomerDistribution as CustomerDistributionModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';

export class CustomerDistribution extends AbstractBCMProvider {
  name = PROVIDER.CUSTOMER_DISTRIBUTIONS;
  management = MANAGEMENT.COMPANY;
  model = new CustomerDistributionModel();

  constructor(props) {
    super(props);
  }
}
