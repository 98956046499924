import {AbstractModel} from '../../Abstract/Model';

export class ModelInstance {
  '@id' = '';
  id = '';
  name = '';
  fullname = '';
  createdBy = '';
  createdAt = '';
  updatedBy = '';
  updatedAt = '';
  deletedBy = '';
  deletedAt = '';

  constructor(object) {
    this['@id'] = object['@id'];
    this.createdBy = object.createdBy;
    this.createdAt = object.createdAt;
    this.updatedBy = object.updatedBy;
    this.updatedAt = object.updatedAt;
    this.deletedBy = object.deletedBy;
    this.deletedAt = object.deletedAt;
  }
}

export class Model extends AbstractModel {}
