import {AbstractBCMProvider} from './abstract';
import {Company as CompanyModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {CompanySerializer} from '../serializer';

export class Company extends AbstractBCMProvider {
  name = PROVIDER.COMPANIES;
  management = MANAGEMENT.COMPANY;
  model = new CompanyModel();
  serializer = new CompanySerializer();

  constructor(props) {
    super(props);
    if (this.filter?.q) {
      this.filter = {...this.filter, companyLocalName: this.filter.q};
    }
    if (this.filter?.name) {
      this.filter = {...this.filter, fullName: this.filter.name};
    }
    if (undefined !== this.filter?.central) {
      this.filter = {...this.filter, centralManaged: this.filter.central};
    }
    if (this.filter?.company_types) {
      this.filter = {...this.filter, idCompanyType: this.filter.company_types};
    }
  }
}
