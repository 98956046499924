import {Model, ModelInstance} from './Common';

class TranslationTableFieldInstance extends ModelInstance {
  fieldName = '';

  constructor(translationTableField) {
    super(translationTableField);
    this.id = translationTableField.idTranslationTableField;
    this.name = translationTableField.translationTableName;
    this.fieldName = translationTableField.translationFieldName;
    this.fullname = `${this.name} - ${this.fieldName}`;
  }
}

export class TranslationTableField extends Model {
  getMany = translationTableFields =>
    translationTableFields.map(
      translationTableField => new TranslationTableFieldInstance(translationTableField)
    );
  getOne = translationTableField =>
    new TranslationTableFieldInstance(translationTableField);
}
