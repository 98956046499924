import {Model, ModelInstance} from './Common';

export class OrganizationTypeInstance extends ModelInstance {
  constructor(organizationType) {
    super(organizationType);
    this.id = organizationType.idOrganizationType;
    this.name = organizationType.organizationTypeName;
  }
}

export class OrganizationType extends Model {
  getMany = organizationTypes =>
    organizationTypes.map(
      organizationType => new OrganizationTypeInstance(organizationType)
    );
  getOne = organizationType => new OrganizationTypeInstance(organizationType);
}
