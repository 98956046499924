import React from 'react';
import {CommonList} from '../Common/List';
import {NUMBER_FIELD} from '../Common';
import {booleanFilter, inputFilter, selectFilter} from '../Filters';
import {getCommonFields} from './Common';
import {PROVIDER} from '../../resources';
import {withStyles} from '@material-ui/core/styles';

export const TranslationList = withStyles({
  tableFieldInput: {minWidth: 200},
  translatedInput: {minWidth: 150},
})(props => (
  <CommonList
    {...props}
    bulkActionButtons={false}
    edit
    fields={[
      ...getCommonFields(),
      {
        label: 'Translation count',
        source: 'count',
        type: NUMBER_FIELD,
      },
    ]}
    filters={[
      key =>
        selectFilter({
          alwaysOn: true,
          className: props.classes.tableFieldInput,
          key,
          label: 'Table - Field',
          optionName: 'fullname',
          source: 'idTranslationTableField',
          reference: PROVIDER.TRANSLATION_TABLE_FIELDS,
        }),
      key =>
        inputFilter({
          alwaysOn: true,
          key,
          label: 'Text',
          source: 'baseText',
        }),
      key =>
        booleanFilter({
          alwaysOn: true,
          className: props.classes.translatedInput,
          choices: [
            {name: 'Translated only', id: true},
            {name: 'Not translated only', id: false},
          ],
          key,
          source: 'translated',
        }),
    ]}
  />
));
