import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class MaterialSubFamilySerializer extends BCMSerializer {
  base = PROVIDER.MATERIAL_SUB_FAMILIES;
  materialFamily = '';
  materialSubFamilyCode = '';
  materialSubFamilyName = '';

  serialize(materialSubFamily) {
    this.idMaterialFamily = materialSubFamily.family.id;
    this.materialSubFamilyCode = materialSubFamily.code;
    this.materialSubFamilyName = materialSubFamily.name;
  }
}
