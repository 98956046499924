import React from 'react';
import {CommonList} from '../Common';

export const RoleList = props => (
  <CommonList
    {...props}
    bulkActionButtons={false}
    fields={[
      {
        source: 'name',
      },
    ]}
  />
);
