import {Model, ModelInstance} from './Common';

class CurrencyInstance extends ModelInstance {
  constructor(currency) {
    super(currency);
    this.code = currency.value.toLowerCase();
    this.name = `${this.code.toUpperCase()} ${currency.label}`;
  }
}

export class Currency extends Model {
  getMany = currencies => currencies.map(currency => new CurrencyInstance(currency));
  getOne = currency => new CurrencyInstance(currency);
}
