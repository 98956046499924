import React from 'react';
import {
  Flight,
  DirectionsCar,
  Build,
  DeleteOutline,
  Train,
  Loop,
  Shuffle,
  Commute,
  LocalGasStation,
  BatteryChargingFull,
  Brush,
  LocalShipping,
} from '@material-ui/icons';

export const ArrayList = ({...rest}) => {
  const {record, Label} = rest;
  const {assoRecyclingDomains} = record;
  const renderIconByDomain = domainName => {
    switch (domainName) {
      case 'Aeronotic':
        return <Flight />;
      case 'Automotive':
        return <DirectionsCar />;
      case 'Construction':
        return <Build />;
      case 'Other':
        return <DeleteOutline />;
      case 'Railway':
        return <Train />;
      case 'Recycling':
        return <Loop />;
      case 'Scrap retail':
        return <Shuffle />;
      case 'Transport':
        return <Commute />;
      case 'Oil and Gas':
        return <LocalGasStation />;
      case 'Energy':
        return <BatteryChargingFull />;
      case 'Varnishes & powder paint':
        return <Brush />;
      case 'Tractor & Heavy Duty vehicle':
        return <LocalShipping />;
    }
  };
  return (
    <div className="MuiFormControl-root MuiFormControl-marginDense">
      <label
        style={{position: 'relative', marginBottom: '0.5rem'}}
        className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-shrink"
      >
        <span>{Label}</span>
      </label>
      <ul
        style={{padding: '0', marginLeft: '0.5rem', listStyle: 'none'}}
        className="MuiTypography-root MuiTypography-body2"
      >
        {assoRecyclingDomains?.map((item, i) => (
          <li style={{margin: '10px', display: 'flex', alignItems: 'center'}} key={i}>
            {renderIconByDomain(item.domainName)}
            <span style={{padding: '0 0.5rem 0 0.5rem'}} key={item.domainName}>
              {`${item.domainName} : `}
            </span>
            <span key={item.distribution}>{` ${item.distribution}%`}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
