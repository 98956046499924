import {Model, ModelInstance} from './Common';
import {CompanyInstance} from './Company';
import {Material} from './Material';
import {User} from '../../apiPlatform/model/User';

export class ContainerInstance extends ModelInstance {
  address = '';
  city = '';
  countryCode = '';
  countryName = '';
  company = null;
  region = '';
  streetName = '';
  streetNumber = '';
  zipCode = '';
  materials = [];
  operators = [];
  users = [];
  materialsIds = [];
  operatorsIds = [];
  usersIds = [];
  photo = [];

  constructor(container) {
    super(container);
    this.id = container.idContainer;
    this.company = container.company && new CompanyInstance(container.company);
    this.containerCode = container.containerCode;
    this.containerName = container.containerName || '';
    this.countryCode = container.countryCode;
    this.countryName = container.countryName || '';
    this.createdAt = container.createdAt;
    this.latitude = container.latitude || '';
    this.longitude = container.longitude || '';
    this.region = container.region || '';
    this.streetName = container.streetName;
    this.streetNumber = container.streetNumber;
    this.zipCode = container.zipCode;
    this.city = container.city || '';
    this.additionalAddress = container.additionalAddress;
    this.photo = container.photo && container.photo.split(';');
    this.materials = new Material().getMany(container.materials || []);
    this.materialsIds = this.materials.map(o => o.id);
    this.operators = container.operators
      ? new User().getMany(this.serializeOperators(container.operators))
      : [];
    this.users = container.users
      ? new User().getMany(this.serializeUsers(container.users))
      : [];
    this.operatorsIds = this.operators.map(s => s.id);
    this.usersIds = this.users.map(u => u.id);
  }

  serializeOperators(operators) {
    return operators.map(o => {
      o['@id'] = o.idOperator;
      return o;
    });
  }
  serializeUsers(users) {
    return users.map(o => {
      o['@id'] = o.idUser;
      return o;
    });
  }
}

export class Container extends Model {
  getMany = containers => containers.map(container => new ContainerInstance(container));
  getOne = container => new ContainerInstance(container);
}
