import React from 'react';
import {CommonEdit, REFERENCE_ARRAY_INPUT} from '../Common';
import {getInputs} from './Common';
import {PROVIDER} from '../../resources';
import {required} from 'react-admin';

export const UserEdit = props => (
  <CommonEdit
    {...{
      ...props,
      fields: [
        {
          disabled: true,
          source: 'id',
          name: 'id',
        },
        ...getInputs(),
        {
          children: {
            optionText: 'name',
          },
          label: 'Roles',
          source: 'rolesIds',
          reference: PROVIDER.ROLES,
          target: 'id',
          type: REFERENCE_ARRAY_INPUT,
          validate: [required()],
        },
      ],
    }}
  />
);
