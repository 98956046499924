import {Model, ModelInstance} from './Common';
import {Material, MaterialIndexSource, Period} from '../model';

class MaterialIndexValueInstance extends ModelInstance {
  material = null;
  period = null;
  source = null;
  unitValue = 0;

  constructor(miv) {
    super(miv);
    this.id = miv.idMaterialIndexValue;
    this.material = miv.material && new Material().getOne(miv.material);
    this.period = new Period(miv);
    this.source =
      miv.materialIndexSource &&
      new MaterialIndexSource().getOne(miv.materialIndexSource);
    this.unitValue = miv.materialUnitValue;
  }
}

export class MaterialIndexValue extends Model {
  getMany = miv => miv.map(m => new MaterialIndexValueInstance(m));
  getOne = m => new MaterialIndexValueInstance(m);
}
