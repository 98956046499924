import React from 'react';
import {booleanFilter, countryCodeFilter, nameFilter, selectFilter} from '../Filters';
import {CommonList} from '../Common';
import {fields} from './Common';
import {PROVIDER} from '../../resources';

export const CompanyList = props => (
  <CommonList
    {...props}
    bulkActionButtons={false}
    edit
    fields={fields()}
    filters={[
      nameFilter,
      countryCodeFilter,
      key =>
        booleanFilter({
          alwaysOn: true,
          choices: [
            {name: 'Yes', id: true},
            {name: 'No', id: false},
          ],
          key,
          source: 'central',
        }),
      key =>
        selectFilter({
          key,
          label: 'Company type',
          source: PROVIDER.COMPANY_TYPES,
          reference: PROVIDER.COMPANY_TYPES,
        }),
    ]}
    show
  />
);
