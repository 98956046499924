import {AbstractBCMProvider} from './abstract';
import {OrganizationType as OrganizationTypeModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';

export class OrganizationType extends AbstractBCMProvider {
  endpoint = PROVIDER.ORGANIZATION_TYPES;
  management = MANAGEMENT.ORGANIZATION;
  name = PROVIDER.ORGANIZATIONS;
  model = new OrganizationTypeModel();
}
