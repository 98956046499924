import React from 'react';
import {CommonCreate, REFERENCE_ARRAY_INPUT} from '../Common';
import {PROVIDER} from '../../resources';
import {getInputs} from './Common';
import {regex, required} from 'react-admin';

export const UserCreate = props => (
  <CommonCreate
    {...{
      ...props,
      fields: [
        ...getInputs(),
        {
          source: 'password',
          type: 'password',
          validate: [
            required(),
            regex(/[A-Z]/, 'Must contains one uppercase'),
            regex(/[a-z]/, 'Must contains one lowercase'),
            regex(/[0-9]/, 'Must contains one number'),
            regex(/[!@#$%^&*]/, 'Must contains one special char'),
            regex(/.{8,}/, 'Must be at least 8 char length'),
          ],
        },
        {
          children: {
            optionText: 'name',
          },
          label: 'Roles',
          source: 'rolesIds',
          reference: PROVIDER.ROLES,
          getChoices: ({choices, record}) =>
            choices?.filter(c => record.id !== c.id) || [],
          target: 'roles',
          type: REFERENCE_ARRAY_INPUT,
          validate: [required()],
        },
      ],
    }}
  />
);
