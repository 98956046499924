import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class CompanySerializer extends BCMSerializer {
  base = PROVIDER.COMPANIES;
  companyLocalName = '';

  serialize(company) {
    this.companyLocalName = company.name;
    this.companyLocalVATCode = company.vat?.code;
    this.countryCode = company.countryCode;
    this.description = company.description;
    this.idCompanySubType = company.companySubType?.id;
    this.idCompanyType = company.companyType?.id;
    this.localCountryLegalReferentialName = company.countryLegalReferentialName;
    this.localItSystemCompanyUid = company.localItSystemCompanyUid;
    this.localCountryLegalCompanyUid = company.localCountryLegalCompanyUid;
    this.vatApplied = company.vat?.name;
    this.domains = company?.assoRecyclingDomains
      .filter(d => d?.distribution != null && d?.distribution !== 0)
      .map(item => {
        return {id: item.idDomain, value: item.distribution / 100};
      });
  }
}
