import {
  MaterialReferentialCreate,
  MaterialReferentialEdit,
  MaterialReferentialList,
} from '../components/MaterialReferential';
import {
  OrganizationCreate,
  OrganizationEdit,
  OrganizationList,
} from '../components/Organization';
import {
  CompanyCreate,
  CompanyEdit,
  CompanyList,
  CompanyShow,
} from '../components/Company';
import {
  ContainerCreate,
  ContainerEdit,
  ContainerList,
  ContainerShow,
} from '../components/Container';
import {
  ContractCreate,
  ContractEdit,
  ContractList,
  ContractShow,
} from '../components/Contracts';
import {
  ContractIndexValueCreate,
  ContractIndexValueEdit,
  ContractIndexValueList,
  ContractIndexValueShow,
} from '../components/ContractIndexValues';
import {
  CustomerDistributionCreate,
  CustomerDistributionEdit,
  CustomerDistributionList,
  CustomerDistributionShow,
} from '../components/CustomerDistribution';
import {
  MaterialCreate,
  MaterialEdit,
  MaterialList,
  MaterialShow,
} from '../components/Material';
import {
  MaterialFamilyCreate,
  MaterialFamilyEdit,
  MaterialFamilyList,
} from '../components/MaterialFamily';
import {
  MaterialSubFamilyCreate,
  MaterialSubFamilyEdit,
  MaterialSubFamilyList,
  MaterialSubFamilyShow,
} from '../components/MaterialSubFamily';
import {
  MaterialTCO2Create,
  MaterialTCO2Edit,
  MaterialTCO2List,
  MaterialTCO2Show,
} from '../components/MaterialTCO2';
import {API_PLATFORM_PROVIDER} from './apiPlatform';
import {ContactList, ContactShow} from '../components/Contact';
import {
  ExchangeRateCreate,
  ExchangeRateEdit,
  ExchangeRateList,
} from '../components/ExchangeRate';
import {SiteList, SiteShow} from '../components/Site';
import {MaterialReferentialShow} from '../components/MaterialReferential/Show';
import {
  MaterialIndexValuesCreate,
  MaterialIndexValuesEdit,
  MaterialIndexValuesList,
} from '../components/MaterialIndexValues';
import {TranslationEdit, TranslationList} from '../components/Translation';
import {
  DocumentCreate,
  DocumentEdit,
  DocumentList,
  DocumentShow,
} from '../components/Document';
import {Description} from '@material-ui/icons';

const BCM_PROVIDER = {
  COMPANIES: 'companies',
  CONTAINERS: 'containers',
  COMPANY_SUB_TYPES: 'company_subtypes',
  COMPANY_TYPES: 'company_types',
  CONTACTS: 'contacts',
  CONTRACTS: 'contracts',
  CONTRACT_INDEX_VALUES: 'contract_index_values',
  COUNTRYCODES: 'countrycodes',
  CUSTOMER_DISTRIBUTIONS: 'recycling_customer_distributions',
  EXCHANGE_RATES: 'exchange_rates',
  MATERIALS: 'materials',
  MATERIAL_FAMILIES: 'material_families',
  MATERIAL_INDEX_SOURCES: 'material_index_sources',
  MATERIAL_INDEX_VALUES: 'material_index_values',
  MATERIAL_REFERENTIALS: 'material_referentials',
  MATERIAL_REFERENTIAL_TYPES: 'material_referential_types',
  MATERIAL_SUB_FAMILIES: 'material_subfamilies',
  MATERIAL_TCO2: 'materials_tco2',
  MATERIAL_UNITS: 'material_units',
  ORGANIZATIONS: 'organizations',
  ORGANIZATION_HIERARCHIES: 'organization_hierarchies',
  ORGANIZATION_TYPES: 'organization_types',
  RECYCLING_DOMAINS: 'recycling_domains',
  SITES: 'sites',
  TRANSLATIONS: 'translations',
  TRANSLATION_TABLE_FIELDS: 'translation_table_fields',
};

export const PROVIDER = {
  ...API_PLATFORM_PROVIDER,
  ...BCM_PROVIDER,
};

export const MANAGEMENT = {
  COMPANY: 'company',
  CONTAINER: 'container',
  EXCHANGE_RATE: 'exchange_rate',
  MATERIAL: 'material',
  ORGANIZATION: 'organization',
  TRANSLATION: 'translation',
};

export const bcmAllowedResources = Object.keys(BCM_PROVIDER).map(
  k => PROVIDER[k.toString()]
);

const bcmNotIndexedResources = [
  PROVIDER.COMPANY_TYPES,
  PROVIDER.COMPANY_SUB_TYPES,
  PROVIDER.COUNTRYCODES,
  PROVIDER.MATERIAL_INDEX_SOURCES,
  PROVIDER.MATERIAL_REFERENTIAL_TYPES,
  PROVIDER.MATERIAL_UNITS,
  PROVIDER.ORGANIZATION_TYPES,
  PROVIDER.TRANSLATION_TABLE_FIELDS,
  PROVIDER.RECYCLING_DOMAINS,
];

export const bcmIndexedResources = [
  {
    name: PROVIDER.MATERIAL_REFERENTIALS,
    options: {label: 'Material referentials'},
    create: MaterialReferentialCreate,
    edit: MaterialReferentialEdit,
    list: MaterialReferentialList,
    show: MaterialReferentialShow,
  },
  {
    name: PROVIDER.MATERIAL_FAMILIES,
    options: {label: 'Material family'},
    create: MaterialFamilyCreate,
    edit: MaterialFamilyEdit,
    list: MaterialFamilyList,
  },
  {
    name: PROVIDER.MATERIAL_SUB_FAMILIES,
    options: {label: 'Material sub families'},
    create: MaterialSubFamilyCreate,
    edit: MaterialSubFamilyEdit,
    list: MaterialSubFamilyList,
    show: MaterialSubFamilyShow,
  },
  {
    name: PROVIDER.COMPANIES,
    options: {label: 'Company'},
    create: CompanyCreate,
    edit: CompanyEdit,
    list: CompanyList,
    show: CompanyShow,
  },
  {
    name: PROVIDER.CONTAINERS,
    options: {label: 'Containers'},
    create: ContainerCreate,
    edit: ContainerEdit,
    list: ContainerList,
    show: ContainerShow,
  },
  {
    name: PROVIDER.CONTRACTS,
    options: {label: 'Contracts'},
    create: ContractCreate,
    edit: ContractEdit,
    list: ContractList,
    show: ContractShow,
  },
  {
    name: PROVIDER.CONTRACT_INDEX_VALUES,
    options: {label: 'Contract index values'},
    create: ContractIndexValueCreate,
    edit: ContractIndexValueEdit,
    list: ContractIndexValueList,
    show: ContractIndexValueShow,
  },
  {
    name: API_PLATFORM_PROVIDER.DOCUMENTS,
    options: {label: 'Documents'},
    list: DocumentList,
    create: DocumentCreate,
    edit: DocumentEdit,
    show: DocumentShow,
    icon: Description,
  },
  {
    name: PROVIDER.SITES,
    options: {label: 'Sites'},
    list: SiteList,
    show: SiteShow,
  },
  {
    name: PROVIDER.ORGANIZATIONS,
    options: {label: 'Organizations'},
    create: OrganizationCreate,
    edit: OrganizationEdit,
    list: OrganizationList,
  },
  {
    name: PROVIDER.CONTACTS,
    options: {label: 'Contacts'},
    list: ContactList,
    show: ContactShow,
  },
  {
    name: PROVIDER.EXCHANGE_RATES,
    options: {label: 'Exchange rates'},
    create: ExchangeRateCreate,
    edit: ExchangeRateEdit,
    list: ExchangeRateList,
  },
  {
    name: PROVIDER.TRANSLATIONS,
    options: {label: 'Translations'},
    edit: TranslationEdit,
    list: TranslationList,
  },
  {
    name: PROVIDER.MATERIALS,
    options: {label: 'Material'},
    create: MaterialCreate,
    edit: MaterialEdit,
    show: MaterialShow,
    list: MaterialList,
  },
  {
    name: PROVIDER.MATERIAL_INDEX_VALUES,
    options: {label: 'Material index values'},
    create: MaterialIndexValuesCreate,
    edit: MaterialIndexValuesEdit,
    list: MaterialIndexValuesList,
  },
  {
    name: PROVIDER.MATERIAL_TCO2,
    options: {label: 'Materials Teq CO2'},
    create: MaterialTCO2Create,
    edit: MaterialTCO2Edit,
    show: MaterialTCO2Show,
    list: MaterialTCO2List,
  },
  {
    name: PROVIDER.CUSTOMER_DISTRIBUTIONS,
    options: {label: 'Industry distribution'},
    create: CustomerDistributionCreate,
    edit: CustomerDistributionEdit,
    list: CustomerDistributionList,
    show: CustomerDistributionShow,
  },
];

export const bcmApiResources = [
  ...bcmNotIndexedResources.map(i => ({name: i})),
  ...bcmIndexedResources,
];
