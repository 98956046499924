import {AbstractBCMProvider} from './abstract';
import {Contact as ContactModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';

export class Contact extends AbstractBCMProvider {
  endpoint = PROVIDER.CONTACTS;
  management = MANAGEMENT.COMPANY;
  name = PROVIDER.CONTACTS;
  model = new ContactModel();

  constructor(props) {
    super(props);
    if (this.filter?.company) {
      this.filter = {...this.filter, idCompanies: [this.filter.company.id]};
    }
  }
}
