import {AbstractBCMProvider} from './abstract';
import {MaterialReferentialType as MaterialReferentialTypeModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';

export class MaterialReferentialType extends AbstractBCMProvider {
  endpoint = PROVIDER.MATERIAL_REFERENTIAL_TYPES;
  management = MANAGEMENT.MATERIAL;
  model = new MaterialReferentialTypeModel();
  name = PROVIDER.MATERIALS;
}
