import {BOOLEAN_FIELD, REFERENCE_FIELD} from '../Common';
import {PROVIDER} from '../../resources';

const commonFields = [
  {source: 'firstname'},
  {source: 'lastname'},
  {source: 'email'},
  {
    source: 'active',
    type: BOOLEAN_FIELD,
  },
];

export const getListFields = () => [
  ...commonFields,
  {
    children: {source: 'fullname'},
    label: 'Company',
    link: 'show',
    reference: PROVIDER.COMPANIES,
    source: 'company.id',
    type: REFERENCE_FIELD,
  },
];

export const getShowFields = () => [
  {source: 'id'},
  {
    children: {source: 'fullname'},
    label: 'Company',
    link: 'show',
    reference: PROVIDER.COMPANIES,
    source: 'company.id',
    type: REFERENCE_FIELD,
  },
  ...commonFields,
  {source: 'phoneNumber'},
  {source: 'mobileNumber'},
  {source: 'faxNumber'},
  {source: 'role'},
  {source: 'roleDescription'},
];
