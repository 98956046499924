import {Model, ModelInstance} from './Common';
import {CompanyTypeInstance} from './CompanyType';
import {VatInstance} from './Vat';
import {CompanySubTypeInstance} from './CompanySubType';
import {Organization} from './Organization';
import {Contact} from './Contact';
import {MaterialReferentialInstance} from './MaterialReferential';
import {Site} from './Site';

export class CompanyInstance extends ModelInstance {
  central = true;
  localItSystemCompanyUid = '';
  localCountryLegalCompanyUid = '';
  countryLegalReferentialName = '';
  description = '';
  vat = null;
  companyType = null;
  companySubType = null;
  countryCode = '';
  internationalName = '';
  organizations = [];
  organizationsIds = [];
  contacts = [];
  materialReferential = null;
  sites = [];
  assoRecyclingDomains = [];
  /*stocks = 0;
  movements = 0;*/

  constructor(company) {
    super(company);
    this.id = company.idCompany;
    this.central = company.centralManaged;
    this.code = company.countryCode;
    this.localItSystemCompanyUid = company.localITSystemCompanyUID;
    this.localCountryLegalCompanyUid = company.localCountryLegalCompanyUID;
    this.countryLegalReferentialName = company.localCountryLegalReferentialName;
    this.name = company.companyLocalName;
    this.description = company.description;
    this.vat = new VatInstance(company);
    this.companyType =
      company.companyType && new CompanyTypeInstance(company.companyType);
    this.companySubType =
      company.companySubType && new CompanySubTypeInstance(company.companySubType);
    this.countryCode = company.countryCode;
    this.internationalName = company.companyInternationalizedName;
    this.organizations = new Organization().getMany(company.organizations || []);
    this.contacts = new Contact().getMany(company.contacts || []);
    this.materialReferential =
      company.materialReferential &&
      new MaterialReferentialInstance(company.materialReferential);
    this.sites = new Site().getMany(company.sites || []);
    /*this.stocks = company.stocks?.length || '0';
    this.movements = company.movements?.length || '0';*/
    this.organizationsIds = this.organizations.map(o => o.id);
    this.sitesIds = this.sites.map(s => s.id);
    this.contactsIds = this.contacts.map(c => c.id);

    this.fullname = `(${this.countryCode}) ${this.name}`;
    this.assoRecyclingDomains =
      company.assoRecyclingDomains &&
      company.assoRecyclingDomains?.map(item => {
        item.distribution = item.distribution * 100;
        return item;
      });
  }
}

export class Company extends Model {
  getMany = companies => companies.map(company => new CompanyInstance(company));
  getOne = company => new CompanyInstance(company);
}
