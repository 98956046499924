import React from 'react';
import {CommonShow} from '../Common';
import {getShowFields} from './Common';

export const ContainerShow = props => (
  <CommonShow
    {...{
      ...props,
      fields: getShowFields(),
    }}
  />
);
