import React from 'react';
import {CommonShow} from '../Common';
import {showFields} from './Common';

export const CompanyShow = props => (
  <CommonShow
    {...{
      ...props,
      fields: showFields(),
    }}
  />
);
