import {Model, ModelInstance} from './Common';

class MaterialIndexSourceInstance extends ModelInstance {
  code = '';
  currency = '';

  constructor(source) {
    super(source);
    this.id = source.idMaterialIndexSource;
    this.code = source.materialIndexSourceCode;
    this.name = source.materialIndexSourceName;
    this.currency = source.currencyCode;
  }
}

export class MaterialIndexSource extends Model {
  getMany = sources => sources.map(s => new MaterialIndexSourceInstance(s));
  getOne = s => new MaterialIndexSourceInstance(s);
}
