import React from 'react';
import {CommonList} from '../Common';
import {nameFilter} from '../Filters';

export const MaterialReferentialList = props => (
  <CommonList
    {...{
      ...props,
      edit: true,
      fields: [
        {
          source: 'name',
        },
        {
          label: 'Material Referential type',
          source: 'materialReferentialTypeResponse.name',
        },
      ],
      filters: [nameFilter],
      show: true,
      title: props.options.label,
    }}
  />
);
