import React from 'react';
import {CommonEdit} from '../Common';
import {getInputs} from './Common';
import {SaveButton, Toolbar} from 'react-admin';

export const TranslationEdit = props => (
  <CommonEdit
    {...{
      ...props,
      children: {
        toolbar: (
          <Toolbar {...props}>
            <SaveButton />
          </Toolbar>
        ),
      },
      fields: [
        {
          disabled: true,
          source: 'id',
        },
        ...getInputs(),
      ],
    }}
  />
);
