import {NUMBER, REFERENCE_INPUT} from '../Common';
import {minValue, number, regex, required} from 'react-admin';
import {API_PLATFORM_PROVIDER as PROVIDER} from '../../resources/apiPlatform';

export const getInputs = () => [
  {
    initialValue: 'EUR',
    disabled: true,
    label: 'From currency code',
    source: 'currencies.from',
  },
  {
    inputProps: {min: 0.0},
    source: 'value',
    type: NUMBER,
    validate: [number(), minValue(0)],
  },
  {
    children: {
      optionText: 'name',
      optionValue: 'code',
    },
    inputProps: {maxLength: 10},
    label: 'To currency code',
    reference: PROVIDER.CURRENCIES,
    source: 'currencies.to',
    target: PROVIDER.CURRENCIES,
    type: REFERENCE_INPUT,
    validate: [required('The target currency code is mandatory')],
    getChoices: rest =>
      rest.choices.map(currency => ({...currency, code: currency.code.toUpperCase()})),
  },
  {
    source: 'period',
    validate: [
      required('The period is mandatory'),
      regex(/^\d{4}-(0[1-9]|1[0-2])$/, "The period must match the format 'YYYY-MM'"),
    ],
  },
];
