import {AbstractModel} from '../../Abstract/Model';

export class ModelInstance {
  constructor(object) {
    this['@id'] = object['@id'] || '';
    const split = this['@id'].split('/');
    this.id = split[(split.length - 1).toString()];
  }
}

export class Model extends AbstractModel {}
