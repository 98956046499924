import {AbstractBCMProvider} from './abstract';
import {RecyclingDomain as RecyclingDomainModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';

export class RecyclingDomain extends AbstractBCMProvider {
  name = PROVIDER.RECYCLING_DOMAINS;
  management = MANAGEMENT.COMPANY;
  model = new RecyclingDomainModel();
  endpoint = PROVIDER.RECYCLING_DOMAINS;
}
