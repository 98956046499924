import jwtDecode from 'jwt-decode';

class LocalStorage {
  key = '';

  get() {
    return localStorage.getItem(this.key);
  }

  set(value) {
    return localStorage.setItem(this.key, value);
  }

  delete() {
    return localStorage.removeItem(this.key);
  }
}

export class Token extends LocalStorage {
  key = 'token';

  getDecoded() {
    return jwtDecode(this.get() || '');
  }
}

export class Username extends LocalStorage {
  key = 'username';
}

export class Sidebar extends LocalStorage {
  key = 'sidebar';
}
