import {bcmDataProvider} from './BCMApi';
import {bcmAllowedResources} from '../resources';
import {apiPlatformDataProvider} from './APIPlatform.js';

const providerFactory = ({params, resource}) =>
  resource && bcmAllowedResources.includes(resource)
    ? bcmDataProvider({params, resource})
    : apiPlatformDataProvider({params, resource});

export const dataProvider = {
  getList: (resource, params) => providerFactory({params, resource}).getList(),
  getOne: (resource, params) => providerFactory({params, resource}).getOne(params),
  getMany: (resource, params) => providerFactory({params, resource}).getMany(params),
  getManyReference: (resource, params) =>
    providerFactory({params, resource}).getManyReference({ids: [params.id]}),
  create: (resource, params) => providerFactory({params, resource}).create(params),
  update: (resource, params) => providerFactory({params, resource}).update(params),
  delete: (resource, params) => providerFactory({params, resource}).delete(params),
  deleteMany: (resource, params) => providerFactory({params, resource}).deleteMany(),
};
