import {Model, ModelInstance} from './Common';

class ImageInstance extends ModelInstance {
  constructor(image) {
    super(image);
    this.id = image.id || '';
    this.url = image.url || '';
  }
}

export class Image extends Model {
  getMany = images => images.map(image => new ImageInstance(image));
  getOne = image => new ImageInstance(image);
}
