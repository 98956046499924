import {AbstractBCMProvider} from './abstract';
import {MaterialSubFamily as MaterialSubFamilyModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {MaterialSubFamilySerializer} from '../serializer';

export class MaterialSubFamily extends AbstractBCMProvider {
  endpoint = PROVIDER.MATERIAL_SUB_FAMILIES;
  management = MANAGEMENT.MATERIAL;
  model = new MaterialSubFamilyModel();
  name = PROVIDER.MATERIALS;
  serializer = new MaterialSubFamilySerializer();

  constructor(props) {
    super(props);
    if (this.filter?.name) {
      this.filter = {...this.filter, materialSubFamilyName: this.filter.name};
    }
  }
}
