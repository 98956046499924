import React from 'react';
import {CommonList, NUMBER, REFERENCE_FIELD} from '../Common';
import {inputFilter, nameFilter, selectFilter} from '../Filters';
import {PROVIDER} from '../../resources';

export const OrganizationList = props => (
  <CommonList
    {...props}
    edit
    fields={[
      {
        source: 'name',
      },
      {
        label: 'Organization type',
        source: 'organizationType.name',
      },
      {
        label: 'Organization level',
        source: 'level',
      },
      {
        children: {source: 'name'},
        label: 'Parent organization',
        link: 'show',
        reference: PROVIDER.ORGANIZATIONS,
        source: 'parent.id',
        type: REFERENCE_FIELD,
      },
      {
        children: {source: 'name'},
        label: 'Material referential',
        link: 'show',
        reference: PROVIDER.MATERIAL_REFERENTIALS,
        source: 'materialReferential.id',
        type: REFERENCE_FIELD,
      },
    ]}
    filters={[
      nameFilter,
      key =>
        inputFilter({
          key,
          inputProps: {max: 6, min: 1},
          source: 'level',
          type: NUMBER,
        }),
      key =>
        inputFilter({
          key,
          label: 'Parent name',
          source: 'parentOrganizationName',
        }),
      key =>
        selectFilter({
          key,
          label: 'Material referential',
          source: 'referential',
          reference: PROVIDER.MATERIAL_REFERENTIALS,
        }),
    ]}
  />
);
