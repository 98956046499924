import React from 'react';
import {booleanFilter, inputFilter, nameFilter, selectFilter} from '../Filters';
import {CommonList} from '../Common';
import {PROVIDER} from '../../resources';
import {REFERENCE_FIELD} from '../Common/FieldGuesser';
import {withStyles} from '@material-ui/core/styles';

export const MaterialList = withStyles({selectInput: {width: 300}})(props => (
  <CommonList
    {...{
      ...props,
      bulkActionButtons: false,
      edit: true,
      fields: [
        {
          source: 'code',
        },
        {
          source: 'name',
        },
        {
          children: {source: 'name'},
          label: 'Family',
          link: 'edit',
          reference: PROVIDER.MATERIAL_FAMILIES,
          source: 'family.id',
          type: REFERENCE_FIELD,
        },
        {
          children: {source: 'name'},
          label: 'Sub family',
          link: 'edit',
          reference: PROVIDER.MATERIAL_SUB_FAMILIES,
          source: 'subFamily.id',
          type: REFERENCE_FIELD,
        },
        {
          source: 'countryCode',
        },
        {
          children: {source: 'name'},
          label: 'Material referential',
          link: 'show',
          reference: PROVIDER.MATERIAL_REFERENTIALS,
          source: 'referential.id',
          type: REFERENCE_FIELD,
        },
      ],
      filters: [
        nameFilter,
        key =>
          booleanFilter({
            alwaysOn: true,
            choices: [
              {name: 'Yes', id: true},
              {name: 'No', id: false},
            ],
            key,
            source: 'central',
          }),
        key =>
          inputFilter({
            key,
            source: 'code',
          }),
        key =>
          selectFilter({
            alwaysOn: true,
            className: props.classes.selectInput,
            key,
            label: 'Material referential',
            source: 'referential',
            reference: PROVIDER.MATERIAL_REFERENTIALS,
          }),
      ],
      title: props.options.label,
      show: true,
    }}
  />
));
