import React from 'react';
import {CommonList} from '../Common';
import {nameFilter} from '../Filters';
import {getFields} from './Common';

export const MaterialSubFamilyList = props => (
  <CommonList
    {...{
      ...props,
      edit: true,
      fields: getFields(),
      filters: [nameFilter],
      title: props.options.label,
    }}
  />
);
