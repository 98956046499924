import React from 'react';
import {CommonList} from '../Common';
import {fields} from './Common';
import {companyNameFilter, materialNameFilter} from '../Filters';
import {withStyles} from '@material-ui/core/styles';

export const CustomerDistributionList = withStyles({
  autocompleteInput: {minWidth: 400},
})(props => (
  <CommonList
    {...props}
    bulkActionButtons={false}
    edit
    fields={fields()}
    filters={[companyNameFilter, materialNameFilter]}
    show
  />
));
