export class Period {
  start = new Date().toISOString().split('T')[0];
  end = new Date().toISOString().split('T')[0];

  constructor(period) {
    if (period.validityPeriodStartDate) {
      this.start = new Date(period.validityPeriodStartDate).toISOString().split('T')[0];
    } else {
      this.start = '';
    }
    if (period.validityPeriodEndDate) {
      this.end = new Date(period.validityPeriodEndDate).toISOString().split('T')[0];
    } else {
      this.end = '';
    }
  }
}
