import {
  AUTOCOMPLETE_ARRAY_INPUT,
  AUTOCOMPLETE_INPUT,
  FORMDATA,
  NUMBER,
  REFERENCE_INPUT,
} from '../Common';
import {maxValue, minValue, number, required} from 'react-admin';
import {PROVIDER} from '../../resources/bcmApi';

export const getInputs = () => [
  {
    inputProps: {maxLength: 40},
    source: 'name',
    validate: [required('The organization name is mandatory')],
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Type',
    reference: PROVIDER.ORGANIZATION_TYPES,
    source: 'organizationType.id',
    type: REFERENCE_INPUT,
    validate: [required('The organization type is mandatory')],
  },
  {
    label: 'Material Referential',
    reference: PROVIDER.MATERIAL_REFERENTIALS,
    source: 'materialReferential.id',
    type: AUTOCOMPLETE_INPUT,
    validate: [required('The material referential is mandatory')],
  },
  {
    children: {
      optionText: 'fullname',
    },
    label: 'Companies',
    reference: PROVIDER.COMPANIES,
    source: 'companiesIds',
    target: 'id',
    type: AUTOCOMPLETE_ARRAY_INPUT,
  },
  {
    initialValues: 0,
    inputProps: {max: 5, min: 0},
    label: 'Parent level',
    source: 'parentLevel',
    type: NUMBER,
    validate: [maxValue(5), minValue(0), number()],
  },
  {
    children: {
      children: {
        optionText: 'name',
      },
      type: REFERENCE_INPUT,
    },
    getChoices: ({choices, record}) => choices?.filter(c => record.id !== c.id) || [],
    filter: d => ({organizationLevel: (d && d.parentLevel) || 1}),
    label: 'Parent',
    source: 'parent.id',
    reference: PROVIDER.ORGANIZATIONS,
    target: PROVIDER.ORGANIZATIONS,
    type: FORMDATA,
  },
];
