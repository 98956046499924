import {AbstractBCMProvider} from './abstract';
import {ContractIndexValue as ContractIndexValueModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {ContractIndexValueSerializer} from '../serializer';

export class ContractIndexValue extends AbstractBCMProvider {
  endpoint = PROVIDER.CONTRACTINDEXVALUES;
  management = MANAGEMENT.MATERIAL;
  name = PROVIDER.CONTRACT_INDEX_VALUES;
  model = new ContractIndexValueModel();
  serializer = new ContractIndexValueSerializer();

  constructor(props) {
    super(props);
    if (this.filter?.contracts) {
      this.filter = {...this.filter, idContract: this.filter.contracts};
    }
    if (this.filter?.materials) {
      this.filter = {...this.filter, idMaterial: this.filter.materials};
    }
    if (this.filter?.material_index_sources) {
      this.filter = {
        ...this.filter,
        idMaterialIndexSource: this.filter.material_index_sources,
      };
    }
  }
}
