import {AbstractBCMProvider} from './abstract';
import {Material as MaterialModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {MaterialSerializer} from '../serializer';

export class Material extends AbstractBCMProvider {
  management = MANAGEMENT.MATERIAL;
  model = new MaterialModel();
  name = PROVIDER.MATERIALS;
  serializer = new MaterialSerializer();

  constructor(props) {
    super(props);
    if (this.filter?.name) {
      this.filter = {...this.filter, fullname: this.filter.name};
    }
    if (this.filter?.q) {
      this.filter = {...this.filter, fullname: this.filter.q};
    }
    if (undefined !== this.filter?.central) {
      this.filter = {...this.filter, centralManaged: this.filter.central};
    }
    if (undefined !== this.filter?.referential) {
      this.filter = {...this.filter, idMaterialReferential: this.filter.referential};
    }
  }
}
