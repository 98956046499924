import {Model, ModelInstance} from './Common';
import {CompanyInstance} from './Company';

export class ContactInstance extends ModelInstance {
  constructor(contact) {
    super(contact);
    this.id = contact.idContact;
    this.firstname = contact.firstName || '';
    this.lastname = contact.lastName || '';
    this.phoneNumber = contact.phoneNumber;
    this.mobileNumber = contact.mobileNumber;
    this.faxNumber = contact.faxNumber;
    this.email = contact.email;
    this.role = contact.role || '';
    this.roleDescription = contact.roleDescription;
    this.active = !!contact.active;
    this.company = contact.company && new CompanyInstance(contact.company);

    this.fullname = `${this.lastname} ${this.firstname} (${this.role})`;
  }
}

export class Contact extends Model {
  getMany = contacts => contacts.map(contact => new ContactInstance(contact));
  getOne = contact => new ContactInstance(contact);
}
