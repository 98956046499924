import {AbstractBCMProvider} from './abstract';
import {MaterialTCO2 as MaterialTCO2Model} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {MaterialTCO2Serializer} from '../serializer';

export class MaterialTCO2 extends AbstractBCMProvider {
  endpoint = PROVIDER.MATERIAL_TCO2;
  management = MANAGEMENT.MATERIAL;
  model = new MaterialTCO2Model();
  name = PROVIDER.MATERIALS;
  serializer = new MaterialTCO2Serializer();

  constructor(props) {
    super(props);
    if (undefined !== this.filter?.subfamily) {
      this.filter = {...this.filter, idMaterialSubFamily: this.filter.subfamily};
    }
  }
}
