import React, {useState, useEffect} from 'react';
import {Toolbar, SaveButton, DeleteButton, NumberInput} from 'react-admin';
import {CommonEdit, FORMDATA} from '../Common';
import {getInputs} from './Common';
import {withStyles} from '@material-ui/core';
import {RecyclingDomain} from '../../dataProviders/bcm/providers';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const PostEditActions = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />
    {props.record?.central ? <DeleteButton /> : null}
  </Toolbar>
));

const distributionValidator = (v, {assoRecyclingDomains}) => {
  if (assoRecyclingDomains === undefined) return;
  const totalDistribution = Object.values(assoRecyclingDomains)
    .map(item => {
      return item?.distribution !== undefined && item?.idDomain !== undefined
        ? item.distribution
        : 0;
    })
    .reduce((a, b) => {
      return a + b;
    }, 0);
  return totalDistribution !== 100 && v !== 0
    ? 'Renseigner au moins un domaine. Le total de la répartition doit être égal à 100'
    : null;
};

const getRecyclingDomains = ({assoRecyclingDomains}) => {
  const [recyclingDomains, setRecyclingDomains] = useState([]);
  useEffect(() => {
    new RecyclingDomain({pagination: {page: 1, perPage: 1000}})
      .getList()
      .then(({data}) => setRecyclingDomains(data));
  }, []);

  if (assoRecyclingDomains === undefined) assoRecyclingDomains = [];

  recyclingDomains.map(r => {
    const index =
      (assoRecyclingDomains &&
        assoRecyclingDomains?.findIndex(el => el?.idDomain == r?.id)) ||
      0;
    if (index === -1) {
      assoRecyclingDomains.push({
        distribution: null,
        domainName: r.domainName,
        idDomain: r.id,
      });
    }
  });

  return (
    <>
      <span style={{display: 'block'}} className="MuiFormLabel-root">
        Industry Domains *
      </span>
      <small className="d-block">
        Renseigner au moins un domaine. Le total de la répartition doit être égal à 100.
      </small>
      {assoRecyclingDomains?.map((r, key) => {
        return (
          <NumberInput
            source={`assoRecyclingDomains[${key}][distribution]`}
            label={r?.domainName}
            key={key}
            validate={distributionValidator}
          />
        );
      })}
    </>
  );
};

export const CompanyEdit = props => (
  <CommonEdit
    {...{
      ...props,
      children: {
        toolbar: <PostEditActions />,
      },
      fields: [
        {
          disabled: true,
          source: 'id',
        },
        ...getInputs(),
        {
          renderFormData: getRecyclingDomains,
          type: FORMDATA,
        },
      ],
    }}
  />
);
