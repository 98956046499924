import React from 'react';
import {CommonEdit} from '../Common';
import {getInputs} from './Common';

export const MaterialSubFamilyEdit = props => (
  <CommonEdit
    {...{
      ...props,
      fields: [
        {
          disabled: true,
          source: 'id',
        },
        ...getInputs(),
      ],
    }}
  />
);
