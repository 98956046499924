import {Model, ModelInstance} from './Common';
import {Company} from './Company';
import {Material} from './Material';
import {RecyclingDomainInstance, RecyclingDomain} from './RecyclingDomain';

export class CustomerDistributionInstance extends ModelInstance {
  company = null;
  material = null;
  recyclingDomain = null;
  yearMonth = '';
  distribution = '';
  idCustomer = null;
  idMaterial = null;
  idDomain = null;

  constructor(customerDistribution) {
    super(customerDistribution);
    this.id = customerDistribution.idDistribution;
    this.company = new Company().getOne(customerDistribution.company);
    this.idCustomer = new Company().getOne(customerDistribution.company)?.id;
    this.material = new Material().getOne(customerDistribution.material);
    this.idMaterial = new Material().getOne(customerDistribution.material)?.id;
    this.recyclingDomain = new RecyclingDomainInstance(
      customerDistribution.recyclingDomain
    );
    this.idDomain = new RecyclingDomain().getOne(
      customerDistribution.recyclingDomain
    )?.id;
    this.yearMonth = customerDistribution.yearMonth;
    this.distribution = customerDistribution.distribution;
  }
}

export class CustomerDistribution extends Model {
  getMany = customerDistributions =>
    customerDistributions.map(
      customerDistribution => new CustomerDistributionInstance(customerDistribution)
    );
  getOne = customerDistribution => new CustomerDistributionInstance(customerDistribution);
}
