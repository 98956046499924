import React from 'react';
import {CommonList, REFERENCE_FIELD, BOOLEAN_FIELD} from '../Common';
import {PROVIDER} from '../../resources';
import {BulkDeleteButton} from 'react-admin';
import {usernameFilter, booleanFilter} from '../Filters';
import UserUnlockButton from './UserUnlockButton';

const UserActionButtons = props => (
  <>
    <UserUnlockButton label="Unlock" {...props} />
    <BulkDeleteButton {...props} />
  </>
);

export const UserList = props => {
  return (
    <CommonList
      {...props}
      edit
      fields={[
        {
          label: 'Email',
          source: 'username',
        },
        {
          children: {source: 'fullname'},
          label: 'Company',
          link: 'show',
          reference: PROVIDER.COMPANIES,
          source: 'company',
          type: REFERENCE_FIELD,
        },
        {
          children: {source: 'name'},
          label: 'Organization',
          link: 'show',
          reference: PROVIDER.ORGANIZATIONS,
          source: 'organization',
          type: REFERENCE_FIELD,
        },
        {
          source: 'lastConnexionDate',
          label: 'Last login date',
        },
        {
          source: 'tooManyPasswordAttempt',
          label: 'Blocked',
          type: BOOLEAN_FIELD,
        },
      ]}
      filters={[
        usernameFilter,
        key =>
          booleanFilter({
            choices: [
              {name: 'Blocked', id: true},
              {name: 'Not blocked', id: false},
            ],
            key,
            source: 'tooManyPasswordAttempt',
          }),
      ]}
      bulkActionButtons={<UserActionButtons />}
    />
  );
};
