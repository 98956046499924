import {AbstractProvider} from '../../Abstract';

export class AbstractBCMProvider extends AbstractProvider {
  formatQueryParameters = () => {
    const queryParametersObject = Object.entries({
      ...this.pagination,
      ...this.filter,
    }).reduce(
      (a, [k, v]) =>
        v === false
          ? {...a, [k]: v}
          : v
          ? Array.isArray(v) && v.length
            ? {
                ...a,
                [k]: `[${
                  typeof v[0] === 'object' && v[0].value
                    ? v.map(x => x.value).join(',')
                    : v.join(',')
                }]`,
              }
            : v.value || v.value === false
            ? {...a, [k]: v.value}
            : v.length || (!Array.isArray(v) && v)
            ? {...a, [k]: v}
            : a
          : a,
      {}
    );
    return Object.keys(queryParametersObject).length
      ? `?${new URLSearchParams(queryParametersObject).toString()}`
      : '';
  };

  getUrl = (value = '') =>
    `${process.env.REACT_APP_WDH_API_URL}/${this.management}_management/v1/${this
      .endpoint || this.name}${value}${this.formatQueryParameters()}`;
}
