import React from 'react';
import {CommonShow, REFERENCE_ARRAY_FIELD} from '../Common';
import {PROVIDER} from '../../resources';

export const MaterialReferentialShow = props => (
  <CommonShow
    {...{
      ...props,
      fields: [
        {
          source: 'id',
        },
        {
          source: 'name',
        },
        {
          label: 'Material Referential type',
          source: 'materialReferentialTypeResponse.name',
        },
        {
          children: {source: 'name'},
          label: 'Organizations',
          link: 'show',
          reference: PROVIDER.ORGANIZATIONS,
          source: 'organizationsIds',
          type: REFERENCE_ARRAY_FIELD,
        },
        {
          children: {source: 'fullname'},
          label: 'Companies',
          link: 'show',
          reference: PROVIDER.COMPANIES,
          source: 'companiesIds',
          type: REFERENCE_ARRAY_FIELD,
        },
        {
          children: {source: 'name'},
          label: 'Referenced referentials',
          link: 'show',
          reference: PROVIDER.MATERIAL_REFERENTIALS,
          source: 'referentialsIds',
          type: REFERENCE_ARRAY_FIELD,
        },
      ],
    }}
  />
);
