import React from 'react';
import {
  Filter,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import {AUTOCOMPLETE_INPUT, DATETIME, InputGuesser, REFERENCE_INPUT} from '../Common';
import {PROVIDER} from '../../resources';

export const Filters = ({filters = [], ...rest}) => (
  <Filter {...rest}>{filters.map((filter, key) => filter(key))}</Filter>
);

export const inputFilter = props => <TextInput {...props} />;

export const booleanFilter = props => (
  <SelectInput
    {...{
      ...props,
      allowEmpty: true,
    }}
  />
);
export const dateFilter = props => (
  <InputGuesser
    {...{
      ...props,
      type: DATETIME,
    }}
  />
);
export const selectFilter = props => (
  <ReferenceInput {...props}>
    <SelectInput optionText={props.optionName} />
  </ReferenceInput>
);
export const selectArrayFilter = props => <SelectArrayInput {...props} />;
export const autoCompleteFilter = props => (
  <InputGuesser
    {...{
      ...props,
      type: AUTOCOMPLETE_INPUT,
    }}
  />
);

export const rolesFilter = ({...rest}) => (
  <InputGuesser
    {...{
      children: {
        optionText: 'name',
      },
      label: 'Roles',
      reference: PROVIDER.ROLES,
      source: 'id',
      type: REFERENCE_INPUT,
      ...rest,
    }}
  />
);

export const countryCodeFilter = key =>
  inputFilter({
    alwaysOn: true,
    inputProps: {
      maxLength: 2,
    },
    source: 'countryCode',
    key,
  });

export const languageFilter = key =>
  inputFilter({
    source: 'language',
    inputProps: {
      maxLength: 2,
    },
    key,
  });

export const nameFilter = key =>
  inputFilter({
    alwaysOn: true,
    source: 'name',
    key,
  });

export const usernameFilter = key =>
  inputFilter({
    alwaysOn: true,
    label: 'Email',
    source: 'username',
    key,
  });

export const companyNameFilter = key =>
  inputFilter({
    alwaysOn: true,
    label: 'Compagnie',
    source: 'CompanyLocalName',
    key,
  });

export const materialNameFilter = key =>
  inputFilter({
    alwaysOn: true,
    label: 'Matière',
    source: 'MaterialName',
    key,
  });
