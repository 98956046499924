import {createTheme} from '@material-ui/core/styles';

const primaryColor = '#030F40';
const borderRadius = '5px';
const primary = {
  light: primaryColor,
  main: primaryColor,
  dark: primaryColor,
  contrastText: '#fff',
};

export const theme = createTheme({
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: primaryColor,
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex',
        marginBottom: '1rem !important',
        '& > div': {
          paddingLeft: '0.5rem',
        },
      },
      marginNormal: {
        marginBottom: '1rem',
        marginTop: '1rem',
      },
      fullWidth: {
        width: '100% !important',
      },
    },
    MuiInput: {
      root: {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        paddingLeft: '0rem !important',
      },
      input: {
        padding: '1.5rem 0.5rem 0.5rem',
      },
      formControl: {
        margin: '0 !important',
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },
    MuiAutocomplete: {
      root: {
        width: '100%',
      },
      input: {
        padding: '1.5rem 0.5rem 0.5rem !important',
      },
    },
    MuiInputLabel: {
      root: {
        transform: 'none',
        top: '50%',
      },
      shrink: {
        transform: 'translateY(50%) scale(0.6)',
      },
      formControl: {
        transform: 'translateY(100%)',
        left: '0.5rem',
      },
    },
    MuiPaper: {
      root: {
        minWidth: '300px',
      },
    },
    MuiTableCell: {
      root: {
        minWidth: 'auto !important',
      },
    },
    MuiSvgIcon: {
      root: {
        minWidth: 'auto !important',
      },
    },
    RaSingleFieldList: {
      root: {
        marginTop: '0',
      },
    },
  },
  palette: {
    primary,
  },
});
