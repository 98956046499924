import {AbstractBCMProvider} from './abstract';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {CompanyType as CompanyTypeModel} from '../model';

export class CompanyType extends AbstractBCMProvider {
  endpoint = PROVIDER.COMPANY_TYPES;
  management = MANAGEMENT.COMPANY;
  model = new CompanyTypeModel();
  name = PROVIDER.COMPANIES;
}
