import React, {useRef, useContext, useState} from 'react';
import {Button, TextField} from '@material-ui/core';
import {LoadingContext, LoadingProvider} from '../../contexts';
import {Loader} from '../Loader';
import Recaptcha from 'react-recaptcha';

let hasCaptcha = false;
const TextInput = ({label, name, type, value, onChange}) => {
  const {isLoading} = useContext(LoadingContext);
  return (
    <TextField
      {...{
        disabled: isLoading,
        id: name,
        label: label || name,
        name,
        onChange,
        required: true,
        type: type || 'text',
        value,
      }}
    />
  );
};

const SubmitButton = ({text = 'submit'}) => {
  const {isLoading} = useContext(LoadingContext);
  return (
    <Button type="submit" color="primary" className="mt-2">
      {isLoading ? <Loader /> : text}
    </Button>
  );
};

export const CustomForm = ({button, className, fields, onSubmit, withRecaptcha}) => {
  const {setIsLoading} = useContext(LoadingContext);
  const [recaptchaRef, setRecaptchaRef] = useState(null);
  const [captchaOK, setCaptchaOK] = useState(false);
  // Note this is to keep data while captcha is being filled, see login.js
  const [formData, setFormData] = useState({});
  const ref = useRef();

  const submit = event => {
    event.preventDefault();
    if (captchaOK === true) {
      setCaptchaOK(false);
      formData['g-recaptcha-response'] = Object.fromEntries(new FormData(event.target))[
        'g-recaptcha-response'
      ];
      return onSubmit(event, formData);
    }

    setFormData(Object.fromEntries(new FormData(event.target)));
    setIsLoading(true);
    recaptchaRef.execute();
  };

  setTimeout(() => {
    if (withRecaptcha && !hasCaptcha && recaptchaRef) {
      recaptchaRef._renderGrecaptcha();
    }
  }, 1000);

  return (
    <form {...{className, onSubmit: submit, ref}}>
      {fields.map((field, key) => (
        <TextInput key={key} {...field} />
      ))}
      {withRecaptcha ? (
        <div className="d-flex pt-2">
          <Recaptcha
            className="m-auto"
            size="invisible"
            ref={setRecaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onloadCallback={() => {
              hasCaptcha = true;
            }}
            verifyCallback={() => {
              setCaptchaOK(true);
              if (ref && ref.current) {
                const button = ref.current.querySelector('button[type="submit"]');

                if (button) {
                  button.click();
                }
              }
            }}
          />
        </div>
      ) : null}
      <SubmitButton {...button} />
    </form>
  );
};

export const Form = props => (
  <LoadingProvider>
    <CustomForm {...props} />
  </LoadingProvider>
);
