import {Model, ModelInstance} from './Common';

export class MaterialReferentialTypeInstance extends ModelInstance {
  constructor(materialReferentialType) {
    super(materialReferentialType);
    this.id = materialReferentialType.idMaterialReferentialType;
    this.name = materialReferentialType.materialReferentialTypeName;
  }
}

export class MaterialReferentialType extends Model {
  getMany = materialReferentialTypes =>
    materialReferentialTypes.map(
      materialReferentialType =>
        new MaterialReferentialTypeInstance(materialReferentialType)
    );
  getOne = materialReferentialType =>
    new MaterialReferentialTypeInstance(materialReferentialType);
}
