import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class MaterialIndexValueSerializer extends BCMSerializer {
  base = PROVIDER.MATERIAL_INDEX_VALUES;
  materialUnitValue = 0;
  validityPeriodStartDate = new Date();
  validityPeriodEndDate = new Date();
  idMaterial = '';
  idMaterialIndexSource = '';

  serialize(miv) {
    this.idMaterial = miv.material.id;
    this.idMaterialIndexSource = miv.source.id;
    this.materialUnitValue = miv.unitValue;
    this.validityPeriodEndDate = new Date(miv.period.end);
    this.validityPeriodStartDate = new Date(miv.period.start);
  }
}
