const Common = {
  filter: ({values, filter, sort}) =>
    values
      .filter(item =>
        filter
          ? Object.keys(filter).every(key => {
              return item[key.toString()]
                ? item[key.toString()].includes(filter[key.toString()])
                : true;
            })
          : true
      )
      .sort(
        (a, b) =>
          sort &&
          ('ASC' === sort.order
            ? a[sort.field] < b[sort.field]
            : b[sort.field] < a[sort.field])
      ),
};

export class AbstractModel {
  getMany = ({count = 6, filter, sort, withChild = false}) => {
    const values = [];

    for (let id = 0; id < count; id++) {
      values.push(this.getOne({id, withChild}));
    }

    const data = Common.filter({values, filter, sort});

    return {
      data,
      total: data.length,
    };
  };

  getManyReference = ({filter, id, sort}) => {
    const values =
      (Array.isArray(id) && id) ||
      this.getMany({count: Math.floor(Math.random() * 6) + 1}).data;
    const data = Common.filter({values, filter, sort});
    return {
      data,
      total: data.length,
    };
  };

  getOne = () => {
    const data = {};
    return {data};
  };
}
