import {AbstractBCMProvider} from './abstract';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {CountryCode as CountryCodeModel} from '../model';

export class CountryCode extends AbstractBCMProvider {
  endpoint = PROVIDER.COUNTRYCODES;
  management = MANAGEMENT.COMPANY;
  model = new CountryCodeModel();
  name = PROVIDER.COMPANIES;
}
