import {Model, ModelInstance} from './Common';
import {MaterialFamilyInstance} from './MaterialFamily';
import {MaterialSubFamilyInstance} from './MaterialSubFamily';
import {MaterialReferentialInstance} from './MaterialReferential';
import {MaterialUnit} from './MaterialUnit';

class MaterialInstance extends ModelInstance {
  central = true;
  code = '';
  countryCode = '';
  description = '';
  photo = '';
  family = null;
  referential = null;
  referencedIds = [];
  materialsIds = {};
  subFamily = null;

  constructor(material) {
    super(material);
    this.code = material.materialCode;
    this.localCode = material.localCountryLegalMaterialCode;
    this.countryCode = material.countryCode;
    this.description = material.description;
    this.id = material.idMaterial;
    this.name = material.materialName || '';
    this.localName = material.localCountryLegalMaterialName || '';
    this.central = !!material.centralManaged;
    this.photo = material.photo && material.photo.split(';');
    this.unit = material.materialUnit && new MaterialUnit().getOne(material.materialUnit);
    this.referentials =
      material.materialReferentials?.map(r => new MaterialReferentialInstance(r)) || [];
    this.referencedIds = this.referentials.map(r => r.id);
    this.family =
      material.materialFamily && new MaterialFamilyInstance(material.materialFamily);
    this.subFamily =
      material.materialSubFamily &&
      new MaterialSubFamilyInstance(material.materialSubFamily);
    this.referential =
      material.materialReferential &&
      new MaterialReferentialInstance(material.materialReferential);
    material.materialReferentials &&
      material.materialReferentials.map(
        r =>
          (this.materialsIds[
            r.idMaterialReferential.toString()
          ] = r.materialMappings?.map(m => new Material().getOne(m.material).id))
      );

    this.fullname = `(${this.countryCode}) ${this.code} - ${this.name}${
      this.referential ? ` (${this.referential?.name})` : ''
    }`;
  }
}

export class Material extends Model {
  getMany = materials => materials.map(material => new MaterialInstance(material));
  getOne = material => new MaterialInstance(material);
}
