import React from 'react';
import {CommonCreate} from '../Common';
import {getInputs} from './Common';

export const ExchangeRateCreate = props => (
  <CommonCreate
    {...{
      ...props,
      fields: getInputs(),
    }}
  />
);
