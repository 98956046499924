import React from 'react';
import {CommonList} from '../Common';
import {getFields} from './Common';
import {autoCompleteFilter} from '../Filters';
import {PROVIDER} from '../../resources';
import {withStyles} from '@material-ui/core/styles';

export const ContractIndexValueList = withStyles({autocompleteInput: {minWidth: 400}})(
  props => (
    <CommonList
      {...{
        ...props,
        edit: true,
        fields: getFields(),
        show: true,
        title: props.options.label,
      }}
      filters={[
        key =>
          autoCompleteFilter({
            alwaysOn: true,
            children: {
              optionText: 'name',
            },
            classes: props.classes.autocompleteInput,
            key,
            label: 'Contract',
            name: PROVIDER.CONTRACTS,
            reference: PROVIDER.CONTRACTS,
            source: 'contract.id',
          }),
        key =>
          autoCompleteFilter({
            alwaysOn: true,
            children: {
              optionText: 'name',
            },
            classes: props.classes.autocompleteInput,
            key,
            label: 'Material Index Source',
            name: PROVIDER.MATERIAL_INDEX_SOURCES,
            reference: PROVIDER.MATERIAL_INDEX_SOURCES,
            source: 'materialIndexSource.id',
          }),
        key =>
          autoCompleteFilter({
            alwaysOn: true,
            children: {
              optionText: 'name',
            },
            classes: props.classes.autocompleteInput,
            key,
            label: 'Material',
            name: PROVIDER.MATERIALS,
            reference: PROVIDER.MATERIALS,
            source: 'material.id',
          }),
      ]}
    />
  )
);
