import {PROVIDER} from '../../resources';
import {AUTOCOMPLETE_INPUT, FORMDATA, REFERENCE_FIELD, DATETIME} from '../Common';
import {required} from 'react-admin';

export const getFields = () => [
  {
    label: 'Name',
    source: 'filename',
  },
  {
    children: {source: 'fullname'},
    label: 'Company',
    link: 'show',
    reference: PROVIDER.COMPANIES,
    source: 'company',
    type: REFERENCE_FIELD,
  },
  {
    label: 'Type',
    source: 'type',
  },
  {
    label: 'Document date',
    source: 'createdAtFormated',
  },
];

const validateDate = value => {
  var today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  if (new Date(value) >= today) {
    return undefined;
  }
  return 'The document date should be in the present or future';
};

export const getInputs = () => [
  {
    label: 'Name',
    source: 'filename',
    inputProps: {maxLength: 128},
  },
  {
    label: 'Type',
    source: 'type',
    defaultValue: 'BRM',
    disabled: true,
    validate: [required('The document type is mandatory')],
  },
  {
    children: {
      children: {
        optionText: 'fullname',
      },
      type: AUTOCOMPLETE_INPUT,
    },
    allowEmpty: true,
    filter: d => ({companyLevel: (d && d.parentLevel) || 1}),
    label: 'Company',
    source: 'company',
    reference: PROVIDER.COMPANIES,
    type: FORMDATA,
    validate: [required('The file company is mandatory')],
  },
  {
    defaultValue: new Date().toISOString().split('T')[0],
    label: 'Document date',
    source: 'createdAt',
    type: DATETIME,
    validate: [validateDate],
  },
];
