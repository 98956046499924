import React, {createElement, useEffect, useState} from 'react';
import {getResources, MenuItemLink, TOGGLE_SIDEBAR} from 'react-admin';
import {Collapse} from '@material-ui/core';
import {ExpandLess, ExpandMore, ViewList} from '@material-ui/icons';
import {connect} from 'react-redux';
import {Sidebar as SidebarHelper} from '../../helpers';
import {
  apiPlatformIndexedResources,
  bcmIndexedResources,
  PROVIDER as P,
} from '../../resources';

const mapStateToProps = state => ({
  resources: getResources(state),
  sidebarOpen: state.admin.ui.sidebarOpen,
});

const mapDispatchToProps = dispatch => ({
  setSidebarOpen: payload =>
    dispatch({
      type: TOGGLE_SIDEBAR,
      payload,
    }),
});

const RESOURCES = {
  authorization: [P.USERS, P.ROLES],
  entities: [P.COMPANIES, P.SITES, P.ORGANIZATIONS, P.CONTACTS],
  others: [
    P.CONTAINERS,
    P.CONTRACTS,
    P.DOCUMENTS,
    P.CONTRACT_INDEX_VALUES,
    P.EXCHANGE_RATES,
    P.TRANSLATIONS,
    P.CUSTOMER_DISTRIBUTIONS,
  ],
};

const categoryGuesser = resources => {
  let items = {
    Catalog: [],
    Entities: [],
    Others: [],
    Authentication: [],
  };
  resources.map(r => {
    if (r.name.includes('material')) {
      return items.Catalog.push(r);
    } else if (RESOURCES.entities.includes(r.name)) {
      return items.Entities.push(r);
    } else if (RESOURCES.authorization.includes(r.name)) {
      return items.Authentication.push(r);
    } else if (RESOURCES.others.includes(r.name)) {
      return items.Others.push(r);
    }
    return null;
  });
  return items;
};

const SubMenu = ({category, onMenuTap, resources}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MenuItemLink
        to="#"
        primaryText={category}
        leftIcon={open ? <ExpandLess /> : <ExpandMore />}
        onClick={() => setOpen(!open)}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {resources.map((resource, i) => (
          <MenuItemLink
            className="pl-2"
            key={i}
            to={`/${resource.name}`}
            leftIcon={createElement(resource.icon || ViewList)}
            primaryText={(resource.options && resource.options.label) || resource.name}
            onClick={onMenuTap}
          />
        ))}
      </Collapse>
    </>
  );
};

export const Sidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(({logout, onMenuTap, sidebarOpen, setSidebarOpen}) => {
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [state, setState] = useState({
    Catalog: [],
    Entities: [],
    Others: [],
    Authorization: [],
  });

  useEffect(() => {
    const sb = JSON.parse(new SidebarHelper().get() || 'true');
    if (sb) {
      setSidebarOpen(sb);
    }
    setIsFirstCall(false);
    setState(categoryGuesser([...apiPlatformIndexedResources, ...bcmIndexedResources]));
  }, [setSidebarOpen]);

  useEffect(() => {
    if (!isFirstCall) {
      new SidebarHelper().set(sidebarOpen);
    }
  }, [isFirstCall, sidebarOpen]);

  return (
    <div
      style={{
        paddingTop: '1rem',
        transition: 'all 1s ease-in-out',
        width: sidebarOpen ? '' : '45px',
      }}
    >
      {Object.entries(state).map(([category, resources], key) => (
        <SubMenu
          key={key}
          {...{
            category,
            onMenuTap,
            resources,
          }}
        />
      ))}
      {logout}
    </div>
  );
});
