import {Model, ModelInstance} from './Common';

export class CountryCodeInstance extends ModelInstance {
  code = '';

  constructor(countryCode) {
    super(countryCode);
    this.id = countryCode.countryCode;
  }
}

export class CountryCode extends Model {
  getMany = codes => codes.map(code => new CountryCodeInstance(code));
  getOne = code => new CountryCodeInstance(code);
}
