import React from 'react';
import {CommonList} from '../Common';
import {getFields} from './Common';
import {autoCompleteFilter, inputFilter} from '../Filters';
import {PROVIDER} from '../../resources';
import {withStyles} from '@material-ui/core/styles';

export const ContainerList = withStyles({autocompleteInput: {minWidth: 400}})(props => (
  <CommonList
    {...props}
    bulkActionButtons={false}
    fields={getFields()}
    edit
    filters={[
      key =>
        inputFilter({
          alwaysOn: true,
          key,
          source: 'ContainerName',
        }),
      key =>
        autoCompleteFilter({
          alwaysOn: true,
          children: {
            optionText: 'name',
          },
          classes: props.classes.autocompleteInput,
          key,
          label: 'Company',
          name: 'idCompany',
          reference: PROVIDER.COMPANIES,
          source: 'company.id',
          target: 'idCompany',
        }),
    ]}
    show
  />
));
