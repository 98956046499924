import {AbstractBCMProvider} from './abstract';
import {Container as ContainerModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {ContainerSerializer} from '../serializer';

export class Container extends AbstractBCMProvider {
  name = PROVIDER.CONTAINERS;
  endpoint = PROVIDER.CONTAINERS;
  management = MANAGEMENT.COMPANY;
  model = new ContainerModel();
  serializer = new ContainerSerializer();

  constructor(props) {
    super(props);
    if (this.filter?.q) {
      this.filter = {...this.filter, companyLocalName: this.filter.q};
    }
  }
}
