import React from 'react';
import {Toolbar, SaveButton, DeleteButton} from 'react-admin';
import {CommonEdit} from '../Common';
import {getInputs} from './Common';
import {withStyles} from '@material-ui/core';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const PostEditActions = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton />
  </Toolbar>
));
export const CustomerDistributionEdit = props => (
  <CommonEdit
    {...{
      ...props,
      children: {
        toolbar: <PostEditActions />,
      },
      fields: [
        {
          disabled: true,
          source: 'id',
        },
        ...getInputs(),
      ],
    }}
    title={`Industry domain distribution #${props?.id}`}
  />
);
