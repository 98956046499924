import {Model, ModelInstance} from './Common';
import {MaterialSubFamilyInstance} from './MaterialSubFamily';

class MaterialTCO2Instance extends ModelInstance {
  subFamily = null;
  year = null;
  teqCO2 = null;

  constructor(materialTCO2) {
    super(materialTCO2);
    this.id = materialTCO2.idMaterialTCO2;
    this.subFamily =
      materialTCO2.materialSubFamily &&
      new MaterialSubFamilyInstance(materialTCO2.materialSubFamily);
    this.year = materialTCO2.year;
    this.teqCO2 = materialTCO2.teqCO2;
  }
}

export class MaterialTCO2 extends Model {
  getMany = materials => materials.map(material => new MaterialTCO2Instance(material));
  getOne = material => new MaterialTCO2Instance(material);
}
