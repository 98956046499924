import React from 'react';
import {FORMDATA, InputGuesser} from '../Common';

const LANGUAGES = ['fr-FR', 'en-US'];

export const getCommonFields = () => [
  {
    disabled: true,
    label: 'Table',
    source: 'name',
  },
  {
    disabled: true,
    label: 'Field',
    source: 'fieldName',
  },
  {
    disabled: true,
    label: 'Initial text',
    source: 'text',
  },
];

const Languages = () => (
  <>
    {LANGUAGES.map((l, key) => (
      <InputGuesser
        key={key}
        {...{
          label: `Translation ${l.toString()}`,
          source: `translations[${l.toString()}]`,
        }}
      />
    ))}
  </>
);

export const getInputs = () => [
  ...getCommonFields(),
  {
    renderFormData: Languages,
    type: FORMDATA,
  },
];
