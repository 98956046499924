import React from 'react';
import {Admin, Resource} from 'react-admin';
import {authProvider} from './authProvider';
import {CustomLayout} from './components/Layout';
import {theme} from './theme';
import {Login} from './components/Connection';
import {dataProvider} from './dataProviders';
import {apiPlatformResources, bcmApiResources} from './resources';
import {ThemeProvider} from '@material-ui/core/styles';

export const App = () => (
  <ThemeProvider theme={theme}>
    <Admin
      {...{
        authProvider,
        dataProvider,
        entrypoint: process.env.REACT_APP_API_ENTRYPOINT,
        layout: CustomLayout,
        loginPage: Login,
        theme,
      }}
    >
      {permissions => [
        ...apiPlatformResources.map((resource, key) => (
          <Resource key={key} {...{...resource, permissions}} />
        )),
        ...bcmApiResources.map((resource, key) => (
          <Resource key={key} {...{...resource, permissions}} />
        )),
      ]}
    </Admin>
  </ThemeProvider>
);
