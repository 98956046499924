import {AbstractBCMProvider} from './abstract';
import {Contract as ContractModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {ContractSerializer} from '../serializer';

export class Contract extends AbstractBCMProvider {
  endpoint = PROVIDER.CONTRACTS;
  management = MANAGEMENT.MATERIAL;
  name = PROVIDER.CONTRACTS;
  model = new ContractModel();
  serializer = new ContractSerializer();

  constructor(props) {
    super(props);
    if (this.filter?.companies) {
      this.filter = {...this.filter, idCompany: this.filter.companies};
    }
  }
}
