import React from 'react';
import {CommonList} from '../Common/List';
import {dateFilter, inputFilter} from '../Filters';

export const ExchangeRateList = props => (
  <CommonList
    {...{
      ...props,
      edit: true,
      fields: [
        {
          label: 'From currency code',
          source: 'currencies.from',
        },
        {source: 'value'},
        {
          label: 'To currency code',
          source: 'currencies.to',
        },
        {source: 'period'},
      ],
      filters: [
        key =>
          inputFilter({
            alwaysOn: true,
            key,
            source: 'toCurrencyCode',
          }),
        key =>
          dateFilter({
            key,
            label: 'Period valid from date',
            source: 'fromDate',
          }),
        key =>
          dateFilter({
            key,
            label: 'Period valid to date',
            source: 'toDate',
          }),
      ],
    }}
  />
);
