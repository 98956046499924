import {Model, ModelInstance} from './Common';

class MaterialUnitInstance extends ModelInstance {
  constructor(materialUnit) {
    super(materialUnit);
    this.id = materialUnit.idMaterialUnit;
    this.code = materialUnit.materialUnitCode;
    this.name = materialUnit.materialUnitName;
  }
}

export class MaterialUnit extends Model {
  getMany = materialUnits =>
    materialUnits.map(materialUnit => new MaterialUnitInstance(materialUnit));
  getOne = materialUnit => new MaterialUnitInstance(materialUnit);
}
