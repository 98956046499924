import {
  REFERENCE_ARRAY_FIELD,
  REFERENCE_ARRAY_INPUT,
  REFERENCE_FIELD,
  REFERENCE_INPUT,
  AUTOCOMPLETE_INPUT,
  FORMDATA,
  AUTOCOMPLETE_ARRAY_INPUT,
} from '../Common';
import {PROVIDER} from '../../resources';
import {required, ReferenceArrayInput, SelectArrayInput} from 'react-admin';
import React from 'react';

const OptionRenderer = item => (
  <div className="material__photo">
    <div className="name" alt={item?.id}>
      test
    </div>
    <img src={item?.url} />
  </div>
);

export const getFields = () => [
  {
    label: 'Name',
    source: 'containerName',
  },
  {
    label: 'Container Code',
    source: 'containerCode',
  },
  {
    label: 'Country code',
    source: 'countryCode',
  },
  {
    children: {source: 'fullname'},
    label: 'Company',
    link: 'show',
    reference: PROVIDER.COMPANIES,
    source: 'company.id',
    type: REFERENCE_FIELD,
  },
];

export const getShowFields = () => [
  {source: 'id'},
  {
    label: 'Name',
    source: 'containerName',
  },
  {
    label: 'Container Code',
    source: 'containerCode',
  },
  {
    label: 'Material',
    link: 'show',
    reference: PROVIDER.MATERIALS,
    source: 'materialsIds',
    type: REFERENCE_ARRAY_FIELD,
  },
  {
    children: {source: 'fullname'},
    label: 'Company',
    link: 'show',
    reference: PROVIDER.COMPANIES,
    source: 'company.id',
    type: REFERENCE_FIELD,
  },
  {
    label: 'Address - Street number',
    source: 'streetNumber',
  },
  {
    label: 'Address - Street name',
    source: 'streetName',
  },
  {
    label: 'Address - Additional address',
    source: 'additionalAddress',
  },
  {
    label: 'Address - Zip code',
    source: 'zipCode',
  },
  {
    label: 'Address - City',
    source: 'city',
  },
  {
    label: 'Address - Region',
    source: 'region',
  },
  {
    label: 'Address - Country name',
    source: 'countryName',
  },
  {
    label: 'Address - GPS Latitude',
    source: 'latitude',
  },
  {
    label: 'Address - GPS Longitude',
    source: 'longitude',
  },
  {
    children: {source: 'username'},
    label: 'Client user(s)',
    link: 'show',
    reference: PROVIDER.USERS,
    source: 'usersIds',
    type: REFERENCE_ARRAY_FIELD,
  },
  {
    children: {source: 'username'},
    label: 'Internal operator(s)',
    link: 'show',
    reference: PROVIDER.USERS,
    source: 'operatorsIds',
    type: REFERENCE_ARRAY_FIELD,
  },
];

const userClientsCompany = ({company}) => {
  let filterExternalOperators = {'roles.name': 'ROLE_EXTERNAL_OPERATOR'};

  if (company?.id) {
    filterExternalOperators.company = company?.id;
  }

  return (
    <ReferenceArrayInput
      {...{
        filter: filterExternalOperators,
        label: `Client user(s)`,
        source: 'usersIds',
        reference: PROVIDER.USERS,
        target: 'users',
        type: FORMDATA,
        perPage: 1000,
      }}
      key={company?.id || 2}
      options={{fullWidthInput: true}}
    >
      <SelectArrayInput {...{optionText: 'username'}} />
    </ReferenceArrayInput>
  );
};

const getOperatorsCompany = ({company}) => {
  let filterInternalOperators = {'roles.name': 'ROLE_INTERNAL_OPERATOR'};

  if (company?.id) {
    filterInternalOperators.company = company?.id;
  }

  return (
    <ReferenceArrayInput
      {...{
        filter: filterInternalOperators,
        label: `Internal operator(s)`,
        source: `operatorsIds`,
        reference: PROVIDER.USERS,
        target: 'operators',
        type: FORMDATA,
        perPage: 1000,
      }}
      key={company?.id || 2}
      options={{fullWidthInput: true}}
    >
      <SelectArrayInput {...{optionText: 'username'}} />
    </ReferenceArrayInput>
  );
};

export let getInputs = () => [
  {
    label: 'Name',
    source: 'containerName',
    validate: [required('The container name is mandatory')],
  },
  {
    label: 'Container code',
    source: 'containerCode',
    validate: [required('The container code is mandatory')],
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Material',
    link: 'edit',
    reference: PROVIDER.MATERIALS,
    source: 'materialsIds',
    type: AUTOCOMPLETE_ARRAY_INPUT,
  },
  {
    children: {
      children: {
        optionText: 'fullname',
      },
      type: AUTOCOMPLETE_INPUT,
    },
    allowEmpty: true,
    filter: d => ({companyLevel: (d && d.parentLevel) || 1}),
    label: 'Company',
    source: 'company.id',
    reference: PROVIDER.COMPANIES,
    type: FORMDATA,
  },
  {
    renderFormData: userClientsCompany,
    type: FORMDATA,
  },
  {
    renderFormData: getOperatorsCompany,
    type: FORMDATA,
  },
  {
    label: 'Address - Street number',
    source: 'streetNumber',
  },
  {
    label: 'Address - Street name',
    source: 'streetName',
  },
  {
    label: 'Address - Additional address',
    source: 'additionalAddress',
  },
  {
    label: 'Address - Zip code',
    source: 'zipCode',
  },
  {
    label: 'Address - City',
    source: 'city',
  },
  {
    label: 'Address - Region',
    source: 'region',
  },
  {
    label: 'Address - GPS Latitude',
    source: 'latitude',
  },
  {
    label: 'Address - GPS Longitude',
    source: 'longitude',
  },
  {
    children: {
      optionText: 'id',
      optionValue: 'id',
    },
    label: 'Address - Country code',
    source: 'countryCode',
    reference: PROVIDER.COUNTRYCODES,
    target: PROVIDER.COUNTRYCODES,
    type: REFERENCE_INPUT,
  },
  {
    label: 'Address - Country name',
    source: 'countryName',
  },
  {
    children: {
      optionText: OptionRenderer,
    },
    label: 'Photos',
    source: 'photo',
    reference: PROVIDER.IMAGES,
    filter: {folder: 'containers'},
    target: 'photo',
    type: REFERENCE_ARRAY_INPUT,
  },
];
