import {Model, ModelInstance} from './Common';

export class CompanyTypeInstance extends ModelInstance {
  companyTypeCode = '';

  constructor(companyType) {
    super(companyType);
    this.id = companyType.idCompanyType;
    this.name = companyType.companyTypeName;
    this.code = companyType.companyTypeCode;
  }
}

export class CompanyType extends Model {
  getMany = companyTypes =>
    companyTypes.map(companyType => new CompanyTypeInstance(companyType));
  getOne = companyType => new CompanyTypeInstance(companyType);
}
