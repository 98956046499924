import React from 'react';
import {
  BooleanField,
  ChipField,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceArrayField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  FileInput,
  FileField,
} from 'react-admin';
import {ArrayList} from './Custom/List';

export const BOOLEAN_FIELD = 'booleanField';
export const DYNAMIC_FIELDS = 'dynamicFields';
export const FUNCTION_FIELD = 'functionField';
export const NUMBER_FIELD = 'numberField';
export const FILE_FIELD = 'fileField';
export const REFERENCE_FIELD = 'referenceField';
export const REFERENCE_ARRAY_FIELD = 'referenceArrayField';
export const REFERENCE_MANY_FIELD = 'referenceManyField';
export const SINGLE_FIELD_LIST = 'singleFieldList';
export const ARRAYLIST = 'arrayList';
export const FieldGuesser = ({children, renderChildren, type, ...rest}) => {
  switch (type) {
    case BOOLEAN_FIELD: {
      return <BooleanField {...rest} />;
    }
    case DYNAMIC_FIELDS: {
      const Tag = renderChildren;
      return <Tag {...{children, ...rest}} />;
    }
    case FUNCTION_FIELD: {
      return <FunctionField {...rest} />;
    }
    case NUMBER_FIELD: {
      return <NumberField {...rest} />;
    }
    case REFERENCE_FIELD: {
      return (
        <ReferenceField {...rest} allowEmpty>
          <FieldGuesser
            render={record =>
              record[children.source] && <ChipField {...{record, ...children}} />
            }
            type={FUNCTION_FIELD}
          />
        </ReferenceField>
      );
    }
    case REFERENCE_ARRAY_FIELD: {
      return (
        <ReferenceArrayField {...rest}>
          <SingleFieldList link={rest.link || 'show'}>
            <ChipField {...{source: 'name', ...children}} />
          </SingleFieldList>
        </ReferenceArrayField>
      );
    }
    case REFERENCE_MANY_FIELD: {
      return (
        <ReferenceManyField {...rest} allowEmpty>
          <SingleFieldList link={rest.link || 'show'}>
            <ChipField {...{source: 'name', ...children}} />
          </SingleFieldList>
        </ReferenceManyField>
      );
    }
    case SINGLE_FIELD_LIST: {
      return (
        <SingleFieldList>
          <ChipField {...rest} />
        </SingleFieldList>
      );
    }
    case ARRAYLIST: {
      return <ArrayList {...rest} />;
    }
    case FILE_FIELD: {
      console.log('🚀 ~ FieldGuesser ~ FILE_FIELD:');
      return (
        <FileInput
          {...{
            ...rest,
          }}
        >
          <FileField source="src" title="title" />
        </FileInput>
      );
    }
    default: {
      return <TextField {...rest} />;
    }
  }
};
