import React, {createContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';

export const LoadingContext = createContext({
  isLoading: false,
});

export const LoadingProvider = connect(
  ({admin: {loading}}) => ({
    loading,
  }),
  {}
)(({children, loading}) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(!!loading);
  }, [loading]);

  return (
    <LoadingContext.Provider value={{isLoading, setIsLoading}}>
      {children}
    </LoadingContext.Provider>
  );
});
