import {AbstractAPIPlatformProvider} from './abstract';
import {Image as ImageModel} from '../model';
import {PROVIDER} from '../../../resources/bcmApi';

export class Image extends AbstractAPIPlatformProvider {
  name = PROVIDER.IMAGES;
  model = new ImageModel();
  getMany = () => {
    return Promise.reject();
  };
  getOne = () => {
    return Promise.reject();
  };
}
