import {Model, ModelInstance} from './Common';

export class RecyclingDomainInstance extends ModelInstance {
  domainCode = '';
  domainName = '';
  constructor(recyclingDomain) {
    super(recyclingDomain);
    this.id = recyclingDomain.idDomain;
    this.domainCode = recyclingDomain.domainCode;
    this.domainName = recyclingDomain.domainName;
  }
}

export class RecyclingDomain extends Model {
  getMany = recyclingDomains =>
    recyclingDomains.map(recyclingDomain => new RecyclingDomainInstance(recyclingDomain));
  getOne = recyclingDomain => new RecyclingDomainInstance(recyclingDomain);
}
