import {PROVIDER} from '../../resources';
import {Currency, Role, User, Image, Document} from './providers';

export const ModelFactory = ({params, resource}) => {
  switch (resource) {
    case PROVIDER.CURRENCIES:
      return new Currency(params);
    case PROVIDER.IMAGES:
      return new Image(params);
    case PROVIDER.ROLES:
      return new Role(params);
    case PROVIDER.USERS:
      return new User(params);
    case PROVIDER.DOCUMENTS:
      return new Document(params);
    default:
      return;
  }
};
