export class AbstractSerializer {
  serialize(obj) {
    Object.keys(obj).map(o => (this[o.toString()] = obj[o.toString()]));
  }

  create(object) {
    this.serialize(object);
    return this;
  }

  update(object) {
    this.serialize(object);
    return this;
  }
}
