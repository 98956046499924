import {AbstractBCMProvider} from './abstract';
import {MaterialReferential as MaterialReferentialModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {MaterialReferentialSerializer} from '../serializer';

export class MaterialReferential extends AbstractBCMProvider {
  endpoint = PROVIDER.MATERIAL_REFERENTIALS;
  management = MANAGEMENT.MATERIAL;
  model = new MaterialReferentialModel();
  name = PROVIDER.MATERIALS;
  serializer = new MaterialReferentialSerializer();

  constructor(props) {
    super(props);
    if (this.filter?.q) {
      this.filter = {...this.filter, materialReferentialName: this.filter.q};
    }
    if (this.filter?.name) {
      this.filter = {...this.filter, materialReferentialName: this.filter.name};
    }
  }
}
