import {required} from 'react-admin';
import {PROVIDER} from '../../resources';
import {REFERENCE_FIELD, REFERENCE_INPUT} from '../Common';

export const getInputs = () => [
  {
    inputProps: {maxLength: 10},
    source: 'code',
  },
  {
    inputProps: {maxLength: 128},
    source: 'name',
    validate: [required('The material referential name is mandatory')],
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Family',
    reference: PROVIDER.MATERIAL_FAMILIES,
    source: 'family.id',
    type: REFERENCE_INPUT,
  },
];

export const getFields = () => [
  {
    source: 'name',
  },
  {
    source: 'code',
  },
  {
    children: {source: 'name'},
    label: 'Family',
    link: 'show',
    reference: PROVIDER.MATERIAL_FAMILIES,
    source: 'family.id',
    type: REFERENCE_FIELD,
  },
];

export const getShowFields = () => [
  {
    source: 'id',
  },
  ...getFields(),
];
