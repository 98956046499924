import {AbstractBCMProvider} from './abstract';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {CompanySubType as CompanySubTypeModel} from '../model';

export class CompanySubType extends AbstractBCMProvider {
  endpoint = PROVIDER.COMPANY_SUB_TYPES;
  management = MANAGEMENT.COMPANY;
  model = new CompanySubTypeModel();
  name = PROVIDER.COMPANIES;
}
