import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class MaterialSerializer extends BCMSerializer {
  base = PROVIDER.MATERIALS;
  countryCode = '';
  materialCode = '';
  localCountryLegalMaterialCode = '';
  materialName = '';
  localCountryLegalMaterialName = '';
  idMappedMaterials = [];
  idDefaultUnit = '';
  idCompany = '';
  photo = '';
  idMaterialFamily = '';
  idMaterialSubFamily = '';
  idMaterialReferential = '';

  serialize(material) {
    this.countryCode = material.countryCode;
    this.description = material.description;
    this.photo = material.photo?.join(';');
    this.materialCode = material.code;
    this.localCountryLegalMaterialCode = material.localCode;
    this.materialName = material.name;
    this.localCountryLegalMaterialName = material.localName;
    this.idMappedMaterials =
      (material.materialsIds &&
        Object.keys(material.materialsIds)
          .map(k => material.materialsIds[k.toString()])
          .flat()) ||
      [];
    this.idDefaultUnit = material.unit?.id || material.unit;
    this.idCompany = material.company?.id || material.company;
    this.idMaterialFamily = material.family?.id;
    this.idMaterialSubFamily = material.subFamily?.id;
    this.idMaterialReferential = material.referential?.id || material.referential;
  }
}
