import React from 'react';
import {CommonCreate} from '../Common';
import {getInputs} from './Common';

export const CustomerDistributionCreate = props => (
  <CommonCreate
    {...{
      ...props,
      fields: getInputs(),
    }}
    title="Create industry domain distribution"
  />
);
