import {AbstractBCMProvider} from './abstract';
import {MaterialIndexValue as MaterialIndexValueModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {MaterialIndexValueSerializer} from '../serializer';

export class MaterialIndexValue extends AbstractBCMProvider {
  management = MANAGEMENT.MATERIAL;
  model = new MaterialIndexValueModel();
  name = PROVIDER.MATERIAL_INDEX_VALUES;
  serializer = new MaterialIndexValueSerializer();
}
