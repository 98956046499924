import React from 'react';
import {AutocompleteInput} from 'react-admin';

export const FieldAutocomplete = ({children, ...rest}) => {
  const choices = {};
  if (rest.getChoices) {
    choices.choices = rest.getChoices(rest);
  }
  return <AutocompleteInput {...children} {...rest} {...choices} />;
};
