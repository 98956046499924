import React from 'react';
import {CommonCreate, FILE} from '../Common';
import {getInputs} from './Common';
import {required} from 'react-admin';

export const DocumentCreate = props => (
  <CommonCreate
    {...{
      ...props,
      fields: [
        {
          label: 'Search document',
          source: 'file',
          type: FILE,
          maxSize: 10000000,
          multiple: false,
          accept:
            'application/pdf, application/x-pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          validate: [required('The document is mandatory')],
        },
        ...getInputs(),
      ],
    }}
  />
);
