import React from 'react';
import {CommonEdit} from '../Common';
import {getInputs} from './Common';

export const MaterialTCO2Edit = props => {
  return (
    <CommonEdit
      {...{
        ...props,
        fields: [
          {
            disabled: true,
            source: 'id',
          },
          ...getInputs(),
        ],
      }}
    />
  );
};
