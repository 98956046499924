import {Model, ModelInstance} from './Common';
import {CompanyInstance} from './Company';

class AbstractSiteTR extends ModelInstance {
  code = '';

  propertyName = '';

  constructor(site) {
    super(site);
    this.id = site[`idSite${this.propertyName}`];
    this.code = site[`site${this.propertyName}Code`];
    this.name = site[`site${this.propertyName}Name`];
  }
}

class SiteType extends AbstractSiteTR {
  propertyName = 'Type';
}
class SiteRole extends AbstractSiteTR {
  propertyName = 'Role';
}

export class SiteInstance extends ModelInstance {
  address = '';
  city = '';
  countryCode = '';
  countryName = '';
  company = null;
  region = '';
  role = null;
  services = [];
  streetName = '';
  streetNumber = '';
  type = null;
  zip = '';

  constructor(site) {
    super(site);
    this.address = site.additionalAddress;
    this.city = site.city || '';
    this.countryCode = site.countryCode;
    this.countryName = site.countryName || '';
    this.company = site.company && new CompanyInstance(site.company);
    this.id = site.idSite;
    this.region = site.region;
    this.role = site.siteRole && new SiteRole(site.siteRole);
    this.services = site.services?.map(s => s.serviceName) || [];
    this.streetName = site.streetName;
    this.streetNumber = site.streetNumber;
    this.type = site.siteType && new SiteType(site.siteType);
    this.zip = site.zipCode;

    this.fullname = `${this.type?.name || ''}, ${this.role?.name || ''} - ${
      this.countryName
    }, ${this.city}`;
  }
}

export class Site extends Model {
  getMany = sites => sites.map(site => new SiteInstance(site));
  getOne = site => new SiteInstance(site);
}
