import {Model, ModelInstance} from './Common';

class TranslationInstance extends ModelInstance {
  fieldName = '';
  rowId = '';
  text = '';
  count = 0;
  translations = [];

  constructor(translation) {
    super(translation);
    const id = this['@id'].split('/');
    id.shift();
    this.id = id.join('/');
    this.name = translation.translationTableName;
    this.rowId = translation.translationRowId;
    this.fieldName = translation.translationFieldName;
    this.text = translation.baseText;
    this.count = translation.translationCount || 0;
    this.translations = translation.translations || {};
  }
}

export class Translation extends Model {
  getMany = translations =>
    translations.map(translation => new TranslationInstance(translation));
  getOne = translation => new TranslationInstance(translation);
}
