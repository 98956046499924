import {PROVIDER} from '../../resources';
import {FUNCTION_FIELD, REFERENCE_FIELD} from '../Common';

export const getFields = () => [
  {
    label: 'Street name',
    source: 'streetName',
  },
  {source: 'city'},
  {
    label: 'Country name',
    source: 'countryName',
  },
  {
    children: {source: 'fullname'},
    label: 'Company',
    link: 'show',
    reference: PROVIDER.COMPANIES,
    source: 'company.id',
    type: REFERENCE_FIELD,
  },
];

export const getShowFields = () => [
  {source: 'id'},
  {
    label: 'BCM local country code',
    source: 'countryCode',
  },
  {
    children: {source: 'fullname'},
    label: 'Company',
    link: 'show',
    reference: PROVIDER.COMPANIES,
    source: 'company.id',
    type: REFERENCE_FIELD,
  },
  {
    label: 'Street number',
    source: 'streetNumber',
  },
  {
    label: 'Street name',
    source: 'streetName',
  },
  {
    label: 'Additional address',
    source: 'address',
  },
  {
    label: 'Zip code',
    source: 'zip',
  },
  {source: 'city'},
  {source: 'region'},
  {
    label: 'Country name',
    source: 'countryName',
  },
  {
    label: 'Site role',
    source: 'role.name',
  },
  {
    label: 'Site type',
    source: 'type.name',
  },
  {
    label: 'Services',
    render: record => record.services.join(', '),
    type: FUNCTION_FIELD,
  },
];
