import React from 'react';
import {CommonList} from '../Common';
import {getFields} from './Common';
import {autoCompleteFilter} from '../Filters';
import {PROVIDER} from '../../resources';
import {withStyles} from '@material-ui/core/styles';

export const ContractList = withStyles({autocompleteInput: {minWidth: 400}})(props => (
  <CommonList
    {...{
      ...props,
      edit: true,
      fields: getFields(),
      show: true,
      title: props.options.label,
    }}
    filters={[
      key =>
        autoCompleteFilter({
          alwaysOn: true,
          children: {
            optionText: 'name',
          },
          classes: props.classes.autocompleteInput,
          key,
          label: 'Company',
          name: PROVIDER.COMPANIES,
          reference: PROVIDER.COMPANIES,
          source: 'company.id',
        }),
    ]}
  />
));
