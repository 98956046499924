import React from 'react';
import {Show, SimpleShowLayout} from 'react-admin';
import {FieldGuesser} from './FieldGuesser';
import {AppInsightProvider} from '../../contexts';

export const CommonShow = ({fields, ...rest}) => (
  <Show
    {...{
      ...rest,
      className: 'p-2',
    }}
  >
    <AppInsightProvider>
      <SimpleShowLayout>{fields.map(FieldGuesser)}</SimpleShowLayout>
    </AppInsightProvider>
  </Show>
);
