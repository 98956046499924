import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class ContainerSerializer extends BCMSerializer {
  base = PROVIDER.CONTAINERS;
  photo = [];

  async serialize(container) {
    this.idCompany = container.company?.id;
    this.containerCode = container.containerCode;
    this.containerName = container.containerName;
    this.countryCode = container.countryCode;
    this.countryName = container.countryName;
    this.latitude = container.latitude;
    this.longitude = container.longitude;
    this.region = container.region;
    this.streetName = container.streetName;
    this.streetNumber = container.streetNumber;
    this.additionalAddress = container.additionalAddress;
    this.zipCode = container.zipCode;
    this.city = container.city;
    this.address = container.additionalAddress;
    this.photo = container.photo?.join(';');
    this.idMaterials = container.materialsIds;
    this.idOperators = container.operatorsIds;
    this.idUsers = container.usersIds;
  }
}
