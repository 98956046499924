import {Model, ModelInstance} from './Common';
import {Contract, Material, MaterialIndexSource, Period} from '../model';

class ContractIndexValueInstance extends ModelInstance {
  offset = null;
  period = null;
  contract = null;
  material = null;
  materialIndexSource = null;

  constructor(data) {
    super(data);
    this.id = data.idContractIndexValues;
    this.offset = data.materialValueIndexOffset;
    this.period = new Period(data);
    this.contract =
      data.idContract && new Contract().getOne({idContract: data.idContract});
    this.material =
      data.idMaterial && new Material().getOne({idMaterial: data.idMaterial});
    this.materialIndexSource =
      data.idMaterialIndexSource &&
      new MaterialIndexSource().getOne({
        idMaterialIndexSource: data.idMaterialIndexSource,
      });
  }
}

export class ContractIndexValue extends Model {
  getMany = data => data.map(m => new ContractIndexValueInstance(m));
  getOne = m => new ContractIndexValueInstance(m);
}
