import * as React from 'react';
import {AppBar, Layout} from 'react-admin';
import {Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Logo} from './';
import './layout.css';
import {Sidebar} from './Sidebar';

const CustomAppbar = withStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
})(({classes, ...rest}) => (
  <AppBar {...rest}>
    <Logo />
    <Typography className="w-100" id="react-admin-title" />
    <span className={classes.spacer} />
  </AppBar>
));

export const CustomLayout = props => (
  <Layout appBar={CustomAppbar} sidebar={Sidebar} {...props} />
);
