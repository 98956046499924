import {Model, ModelInstance} from './Common';
import {Company, Period} from '../model';

class ContractInstance extends ModelInstance {
  name = '';
  description = '';
  period = null;
  localContractUid = '';
  company = null;

  constructor(data) {
    super(data);
    this.id = data.idContract;
    this.name = data.contractName;
    this.description = data.description;
    this.period = new Period(data);
    this.localContractUid = data.localITSystemContractUID;
    this.company = data.idCompany && new Company().getOne({idCompany: data.idCompany});
  }
}

export class Contract extends Model {
  getMany = data => data.map(m => new ContractInstance(m));
  getOne = m => new ContractInstance(m);
}
