import React from 'react';
import {PaginationLimit, Responsive, useTranslate} from 'react-admin';
import {TablePagination} from '@material-ui/core';
import PaginationActions from './PaginationActions';
import {sanitizeListRestProps} from 'ra-core';

const emptyArray = [];

export const CustomPagination = ({
  isLoading,
  perPage,
  page,
  rowsPerPageOptions,
  setPage,
  setPerPage,
  total,
  width,
  ...rest
}) => {
  const translate = useTranslate();
  const getNbPages = () => Math.ceil(total / perPage) || 1;

  const onChangePage = (event, page) => {
    event && event.stopPropagation();
    if (page < 0 || page > getNbPages() - 1) {
      throw new Error(
        translate('ra.navigation.page_out_of_boundaries', {
          page: page + 1,
        })
      );
    }
    setPage(page + 1);
  };

  const handlePerPageChange = event => setPerPage(event.target.value);

  const labelDisplayedRows = ({from, to, count}) =>
    translate('ra.navigation.page_range_info', {
      offsetBegin: from,
      offsetEnd: to,
      total: count,
    });

  if (!isLoading && total === 0) {
    return <PaginationLimit />;
  }

  const commonSizedProps = {
    count: total,
    rowsPerPage: perPage,
    page: page - 1,
    onChangePage,
    component: 'span',
    labelDisplayedRows,
    ...sanitizeListRestProps(rest),
  };

  return (
    <Responsive
      {...{
        width,
        small: <TablePagination {...commonSizedProps} rowsPerPageOptions={emptyArray} />,
        medium: (
          <TablePagination
            {...commonSizedProps}
            onChangeRowsPerPage={handlePerPageChange}
            ActionsComponent={PaginationActions}
            labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        ),
      }}
    />
  );
};
