import {PROVIDER} from '../../resources/bcmApi';
import {
  BOOLEAN,
  BOOLEAN_FIELD,
  REFERENCE_ARRAY_FIELD,
  REFERENCE_ARRAY_INPUT,
  REFERENCE_FIELD,
  REFERENCE_INPUT,
  ARRAYLIST,
} from '../Common';
import {required} from 'react-admin';
export const fields = () => [
  {source: 'name'},
  {source: 'countryCode'},
  {
    label: 'Company Type',
    source: 'companyType.name',
  },
  {
    label: 'Central',
    source: 'central',
    type: BOOLEAN_FIELD,
  },
];

export const showFields = () => [
  {source: 'id'},
  {
    label: 'BCM local country code',
    source: 'countryCode',
  },
  {
    label: 'Country legal company code',
    source: 'localCountryLegalCompanyUid',
  },
  {
    label: 'Company code',
    source: 'localItSystemCompanyUid',
  },
  {source: 'name'},
  {
    label: 'Company Type',
    source: 'companyType.name',
  },
  {
    label: 'Country Legal referential name',
    source: 'countryLegalReferentialName',
  },
  {source: 'description'},
  {
    label: 'VAT Applied',
    source: 'vat.name',
  },
  {
    label: 'VAT code',
    source: 'vat.code',
  },
  {
    label: 'Company sub type',
    source: 'companySubType.name',
  },
  {source: 'internationalName'},
  {
    label: 'Organizations',
    link: 'edit',
    reference: PROVIDER.ORGANIZATIONS,
    source: 'organizationsIds',
    type: REFERENCE_ARRAY_FIELD,
  },
  {
    children: {source: 'fullname'},
    label: 'Contacts',
    reference: PROVIDER.CONTACTS,
    source: 'contactsIds',
    type: REFERENCE_ARRAY_FIELD,
  },
  {
    children: {source: 'name'},
    label: 'Material Referential',
    link: 'show',
    reference: PROVIDER.MATERIAL_REFERENTIALS,
    source: 'materialReferential.id',
    type: REFERENCE_FIELD,
  },
  {
    children: {source: 'fullname'},
    label: 'Sites',
    reference: PROVIDER.SITES,
    source: 'sitesIds',
    type: REFERENCE_ARRAY_FIELD,
  },
  {
    Label: 'Industry Domains',
    source: 'assoRecyclingDomains',
    type: ARRAYLIST,
  },
  /*{
    label: 'Stocks',
    render: record => `${record.stocks} Stocks`,
    type: FUNCTION_FIELD,
  },
  {
    label: 'Movements',
    render: record => `${record.movements} Mvts`,
    type: FUNCTION_FIELD,
  },*/
];

export const getInputs = () => [
  {
    children: {
      optionText: 'id',
      optionValue: 'id',
    },
    label: 'BCM local country code',
    source: 'countryCode',
    reference: PROVIDER.COUNTRYCODES,
    target: PROVIDER.COUNTRYCODES,
    type: REFERENCE_INPUT,
    validate: [required('The country code is mandatory')],
  },
  {
    label: 'Country legal company code',
    source: 'localCountryLegalCompanyUid',
  },
  {
    label: 'Company code',
    source: 'localItSystemCompanyUid',
    validate: [required('The company code is mandatory')],
  },
  {
    source: 'name',
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Company Type',
    source: 'companyType.id',
    reference: PROVIDER.COMPANY_TYPES,
    target: PROVIDER.COMPANY_TYPES,
    type: REFERENCE_INPUT,
    validate: [required('The company type is mandatory')],
  },
  {
    label: 'Country Legal referential name',
    source: 'countryLegalReferentialName',
  },
  {
    source: 'description',
  },
  {
    label: 'VAT Applied',
    source: 'vat.name',
    type: BOOLEAN,
  },
  {
    label: 'VAT code',
    source: 'vat.code',
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Company sub type',
    source: 'companySubType.id',
    reference: PROVIDER.COMPANY_SUB_TYPES,
    target: PROVIDER.COMPANY_SUB_TYPES,
    type: REFERENCE_INPUT,
  },
  {
    disabled: true,
    source: 'internationalName',
  },
  {
    disabled: true,
    label: 'Organizations',
    link: 'edit',
    reference: PROVIDER.ORGANIZATIONS,
    source: 'organizationsIds',
    type: REFERENCE_ARRAY_INPUT,
  },
  {
    children: {source: 'fullname'},
    disabled: true,
    label: 'Contacts',
    reference: PROVIDER.CONTACTS,
    source: 'contactsIds',
    type: REFERENCE_ARRAY_INPUT,
  },
  {
    children: {source: 'name'},
    disabled: true,
    label: 'Material Referential',
    link: 'show',
    reference: PROVIDER.MATERIAL_REFERENTIALS,
    source: 'materialReferential.id',
    type: REFERENCE_INPUT,
  },
  {
    children: {source: 'fullname'},
    disabled: true,
    label: 'Sites',
    reference: PROVIDER.SITES,
    source: 'sitesIds',
    type: REFERENCE_ARRAY_INPUT,
  },
];
