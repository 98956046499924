import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class MaterialFamilySerializer extends BCMSerializer {
  base = PROVIDER.MATERIAL_FAMILIES;
  materialFamily = '';
  materialFamilyCode = '';
  materialFamilyName = '';

  serialize(materialFamily) {
    this.materialFamilyCode = materialFamily.code;
    this.materialFamilyName = materialFamily.name;
  }
}
