import {fetchHydra as baseFetchHydra} from '@api-platform/admin';

export const fetchHeaders = {
  Authorization: `Bearer ${window.localStorage.getItem('token')}`,
};
export const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
  });
