import React from 'react';
import {CommonShow} from '../Common';
import {getFields} from './Common';

export const DocumentShow = props => (
  <CommonShow
    {...{
      ...props,
      fields: getFields(),
    }}
  />
);
