import React from 'react';

export const Logo = () => (
  <div className="d-flex" style={{paddingRight: '1rem'}}>
    <a href="/" style={{display: 'inline-flex'}}>
      <img
        alt="bcm
        icon"
        src="/images/icon.png"
        height={50}
        className="m-auto
        bg-white"
      />
    </a>
  </div>
);
