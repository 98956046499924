import {BCMSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class OrganizationSerializer extends BCMSerializer {
  base = PROVIDER.ORGANIZATIONS;
  idCompanies = [];
  idOrganization = '';
  organizationName = '';
  idOrganizationType = '';
  idMaterialReferential = '';

  serialize(organization) {
    this.idOrganization = organization.id;
    this.organizationName = organization.name;
    if (organization.parent) {
      this.idParentOrganization = organization.parent.id;
    }
    this.idOrganizationType = organization.organizationType
      ? organization.organizationType.id
      : null;
    this.idMaterialReferential =
      organization.materialReferential.id || organization.materialReferential;
    this.idCompanies = organization.companiesIds?.map(c => c.id || c) || [];
  }
}
