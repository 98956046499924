import React from 'react';
import {SelectInput} from 'react-admin';

export const FilteredSelect = ({children, ...rest}) => {
  const choices = {};
  if (rest.getChoices) {
    choices.choices = rest.getChoices(rest);
  }
  return <SelectInput {...children} {...rest} {...choices} />;
};
