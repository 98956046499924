import {AbstractBCMProvider} from './abstract';
import {Organization as OrganizationModel} from '../model';
import {MANAGEMENT, PROVIDER} from '../../../resources/bcmApi';
import {OrganizationSerializer} from '../serializer';

export class Organization extends AbstractBCMProvider {
  management = MANAGEMENT.ORGANIZATION;
  model = new OrganizationModel();
  name = PROVIDER.ORGANIZATIONS;
  serializer = new OrganizationSerializer();

  constructor(props) {
    super(props);
    if (this.filter?.name) {
      this.filter = {...this.filter, organizationName: this.filter.name};
    }
    if (this.filter?.level) {
      this.filter = {...this.filter, organizationLevel: this.filter.level};
    }
    if (this.filter?.referential) {
      this.filter = {...this.filter, idMaterialReferential: this.filter.referential};
    }
  }
}
