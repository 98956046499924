// in ./ResetViewsButton.js
import * as React from 'react';
import {
  Button,
  useDataProvider,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import {VisibilityOff} from '@material-ui/icons';

const UserUnlockButton = ({selectedIds}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = React.useState(false);

  const updateMany = () => {
    setLoading(true);
    Promise.all(
      selectedIds.map(id =>
        dataProvider.update('users', {
          data: {id: id + '/unlock', tooManyPasswordAttempt: false},
        })
      )
    )
      .then(() => {
        refresh();
        notify('Users updated');
        unselectAll('users');
        setLoading(false);
      })
      .catch(err => {
        notify('Error: users not updated', 'warning');
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <Button label="Unlock" disabled={loading} onClick={updateMany}>
      <VisibilityOff />
    </Button>
  );
};

export default UserUnlockButton;
