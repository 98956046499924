import {Model, ModelInstance} from './Common';
import {Currency} from './Currency';

export class PreferenceInstance extends ModelInstance {
  currency = null;
  language = '';

  constructor(preference) {
    super(preference);
    this.language = preference.language;
    this.currency = preference.currency
      ? new Currency().getOne(preference.currency)
      : null;
  }
}

export class Preference extends Model {
  getMany = preferences =>
    preferences.map(preference => new PreferenceInstance(preference));
  getOne = preference => new PreferenceInstance(preference);
}
