import {Model, ModelInstance} from './Common';
import {MaterialReferentialTypeInstance} from './MaterialReferentialType';
import {Company} from './Company';
import {Organization} from './Organization';

export class MaterialReferentialInstance extends ModelInstance {
  materialReferentialTypeResponse = null;
  companies = [];
  companiesIds = [];
  organizations = [];
  organizationsIds = [];
  referentials = [];
  referentialsIds = [];

  constructor(materialReferential) {
    super(materialReferential);
    this.id = materialReferential.idMaterialReferential;
    this.companies = new Company().getMany(materialReferential.companies || []);
    this.referentials = new MaterialReferential().getMany(
      materialReferential.materialReferentials || []
    );
    this.name = materialReferential.materialReferentialName;
    this.materialReferentialTypeResponse =
      materialReferential.materialReferentialType &&
      new MaterialReferentialTypeInstance(materialReferential.materialReferentialType);
    this.organizations = new Organization().getMany(
      materialReferential.organizations || []
    );
    this.organizationsIds = this.organizations.map(o => o.id);
    this.companiesIds = this.companies.map(c => c.id);
    this.referentialsIds = this.referentials.map(r => r.id);
  }
}

export class MaterialReferential extends Model {
  getMany = materialReferentials =>
    materialReferentials.map(
      materialReferential => new MaterialReferentialInstance(materialReferential)
    );
  getOne = materialReferential => new MaterialReferentialInstance(materialReferential);
}
