import {required} from 'react-admin';

export const getInputs = () => [
  {
    inputProps: {maxLength: 10},
    source: 'code',
  },
  {
    inputProps: {maxLength: 128},
    source: 'name',
    validate: [required('The family name is mandatory')],
  },
];
