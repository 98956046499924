import React from 'react';
import {connect} from 'react-redux';
import {userLogin} from 'react-admin';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {Card, CardContent} from '@material-ui/core';
import {Logo} from '../Layout';
import './login.scss';
import {Form} from '../Form';

export const Login = connect(undefined, {userLogin})(({theme, userLogin}) => {
  const onSubmit = (e, formData) => {
    if (!formData) {
      e.preventDefault();
      userLogin(Object.fromEntries(new FormData(e.target)));
      return;
    }

    userLogin(formData);
  };

  return (
    <MuiThemeProvider {...{theme}}>
      <div
        className="h-100 bg-suez-login"
        style={{
          background: 'background-image: url("/resources/bg_login.jpg")',
        }}
      >
        <div className="d-flex h-100 bg-transparency-login">
          <Card className="m-auto">
            <CardContent>
              <Logo />
              <Form
                {...{
                  button: {
                    text: 'submit',
                  },
                  className: 'text-center mt-2',
                  fields: [
                    {
                      label: 'Email',
                      name: 'username',
                    },
                    {
                      label: 'Mot de passe',
                      name: 'password',
                      type: 'password',
                    },
                  ],
                  onSubmit,
                  withRecaptcha: true,
                }}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </MuiThemeProvider>
  );
});
