import {NUMBER, REFERENCE_FIELD, REFERENCE_INPUT} from '../Common';
import {required} from 'react-admin';
import {PROVIDER} from '../../resources/bcmApi';

export const getFields = () => [
  {source: 'id'},
  {
    children: {source: 'name'},
    label: 'Material sub family',
    link: 'show',
    reference: PROVIDER.MATERIAL_SUB_FAMILIES,
    source: 'subFamily.id',
    type: REFERENCE_FIELD,
  },
  {source: 'year'},
  {
    label: 'Teq CO2',
    source: 'teqCO2',
  },
];

export const getInputs = () => [
  {
    children: {
      optionText: 'name',
    },
    label: 'Material sub family',
    source: 'subFamily.id',
    reference: PROVIDER.MATERIAL_SUB_FAMILIES,
    target: PROVIDER.MATERIAL_SUB_FAMILIES,
    type: REFERENCE_INPUT,
    validate: [required('The material sub family is mandatory')],
  },
  {
    source: 'year',
    type: NUMBER,
    validate: [required('The year is mandatory')],
  },
  {
    label: 'Teq CO2',
    source: 'teqCO2',
    type: NUMBER,
  },
];
