import {Model, ModelInstance} from './Common';

export class CompanySubTypeInstance extends ModelInstance {
  constructor(companySubType) {
    super(companySubType);
    this.id = companySubType.idCompanySubType;
    this.name = companySubType.companySubTypeName;
    this.code = companySubType.companySubTypeCode;
  }
}

export class CompanySubType extends Model {
  getMany = companySubTypes =>
    companySubTypes.map(companySubType => new CompanySubTypeInstance(companySubType));
  getOne = companySubType => new CompanySubTypeInstance(companySubType);
}
