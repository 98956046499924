import {Model, ModelInstance} from './Common';

class RoleInstance extends ModelInstance {
  constructor(role) {
    super(role);
    this.name = role.name;
    this.roles = new Role().getMany(role.roles || []);
    this.rolesIds = this.roles.map(r => r.id);
  }
}

export class Role extends Model {
  getMany = roles => roles.map(this.getOne);
  getOne = role => new RoleInstance(role);
}
