import decodeJwt from 'jwt-decode';
import {Token} from './helpers';

export const authenticationTokenUri = `${process.env.REACT_APP_API_ENTRYPOINT}/login`;
export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';

function redirectToLogin() {
  let scrollV;
  let scrollH;
  const loc = window.location;
  if ('pushState' in history) {
    history.pushState('', document.title, '/#/login');
    history.go();
  } else {
    // Prevent scrolling by storing the page's current scroll offset
    scrollV = document.body.scrollTop;
    scrollH = document.body.scrollLeft;

    loc.hash = '#/login';
    loc.location = '/';
    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
}

export const authProvider = {
  checkAuth: () => (localStorage.getItem(TOKEN) ? Promise.resolve() : Promise.reject()),
  login: ({username, ...rest}) => {
    return fetch(
      new Request(authenticationTokenUri, {
        method: 'POST',
        body: new URLSearchParams({
          ...rest,
          grant_type: 'password',
          client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
          client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
          username,
        }).toString(),
        headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded'}),
      })
    )
      .then(response => {
        if (response.status < 200 || response.status >= 300)
          throw new Error(response.statusText);

        return response.json();
      })
      .then(({access_token, refresh_token}) => {
        if (!decodeJwt(access_token).roles.includes('ROLE_ADMIN')) {
          throw new Error('Not admin');
        }

        localStorage.setItem(TOKEN, access_token); // The JWT token is stored in the browser's local storage
        localStorage.setItem(REFRESH_TOKEN, refresh_token);
      })
      .catch(err => {
        console.error(err);
        return;
      });
  },
  logout: () => {
    const token = new Token();
    if (!token.get()) {
      return Promise.resolve();
    }

    localStorage.removeItem(TOKEN);
    redirectToLogin();
    return Promise.resolve();
  },
  getPermissions: () => {
    const token = new Token();
    if (!token.get()) {
      return Promise.reject('No token.');
    }

    const {roles} = token.getDecoded();
    return roles ? Promise.resolve(roles) : Promise.reject();
  },
};
