import {Model, ModelInstance} from './Common';
import {OrganizationTypeInstance} from './OrganizationType';
import {MaterialReferentialInstance} from './MaterialReferential';
import {Company} from './Company';

class OrganizationInstance extends ModelInstance {
  constructor(organization) {
    super(organization);
    this.id = organization.idOrganization;
    this.name = organization.organizationName;
    this.parent =
      organization.parentOrganization &&
      new OrganizationInstance(organization.parentOrganization);
    this.companies =
      (organization.companies?.length && new Company().getMany(organization.companies)) ||
      [];
    this.companiesIds = this.companies.map(c => c.id);
    this.organizationType =
      organization.organizationType &&
      new OrganizationTypeInstance(organization.organizationType);
    this.materialReferential =
      organization.materialReferential &&
      new MaterialReferentialInstance(organization.materialReferential);
    this.organizationHierarchy = organization.organizationHierarchy;
    this.level = organization.organizationLevel;
    this.parentLevel = parseInt(organization.organizationLevel) - 1;
  }
}

export class Organization extends Model {
  getMany = organizations =>
    organizations.map(organization => new OrganizationInstance(organization));
  getOne = organization => new OrganizationInstance(organization);
}
