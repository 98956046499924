import React from 'react';
import {Edit, SimpleForm} from 'react-admin';
import {InputGuesser} from './InputGuesser';
import {AppInsightProvider} from '../../contexts';

export const CommonEdit = ({fields = [], children, ...rest}) => (
  <Edit
    {...{
      ...rest,
      className: 'p-2',
      title: `${rest.options.label} edition`,
      mutationMode: 'pessimistic',
    }}
  >
    <AppInsightProvider>
      <SimpleForm {...children}>
        {fields.map((field, key) => (
          <InputGuesser key={key} {...field} />
        ))}
      </SimpleForm>
    </AppInsightProvider>
  </Edit>
);
