import {PROVIDER} from '../../resources/bcmApi';
import {
  FUNCTION_FIELD,
  REFERENCE_INPUT,
  NUMBER,
  FORMDATA,
  AUTOCOMPLETE_INPUT,
} from '../Common';
import {required, minValue, maxValue, regex} from 'react-admin';

export const fields = () => [
  {
    label: 'Company',
    source: 'company.name',
  },
  {
    label: 'Material',
    source: 'material.name',
  },
  {
    label: 'Industry Domain',
    source: 'recyclingDomain.domainName',
  },
  {
    label: 'Year-Month',
    source: 'yearMonth',
  },
  {
    label: 'Percentage',
    source: 'distribution',
    render: record => `${record.distribution * 100 + '%'}`,
    type: FUNCTION_FIELD,
  },
];

export const showFields = () => [
  {source: 'id'},
  {
    label: 'Company',
    source: 'company.name',
  },
  {
    label: 'Material',
    source: 'material.name',
  },
  {
    label: 'Industry domain',
    source: 'recyclingDomain.domainName',
  },
  {
    label: 'Year-Month',
    source: 'yearMonth',
  },
  {
    label: 'Percentage',
    source: 'distribution',
    render: record => `${record.distribution * 100 + '%'}`,
    type: FUNCTION_FIELD,
  },
];

export const getInputs = () => [
  {
    children: {
      children: {
        optionText: 'fullname',
      },
      type: AUTOCOMPLETE_INPUT,
    },
    filter: d => ({companyLevel: (d && d.parentLevel) || 1}),
    label: 'Company',
    source: 'idCustomer',
    reference: PROVIDER.COMPANIES,
    type: FORMDATA,
    validate: [required('The company is mandatory')],
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Material',
    reference: PROVIDER.MATERIALS,
    source: 'idMaterial',
    type: AUTOCOMPLETE_INPUT,
    validate: [required('The material is mandatory')],
  },
  {
    children: {
      optionText: 'domainName',
    },
    label: 'Industry Domain',
    source: 'idDomain',
    reference: PROVIDER.RECYCLING_DOMAINS,
    target: PROVIDER.RECYCLING_DOMAINS,
    type: REFERENCE_INPUT,
    validate: [required('The industry domain is mandatory')],
  },
  {
    label: 'Year-Month',
    source: 'yearMonth',
    validate: [
      required('The year month is mandatory'),
      regex(/^[0-9]{4}-[0-9]{2}$/, 'Must match YYYY-MM'),
    ],
  },
  {
    label: 'Percentage %',
    source: 'distribution',
    type: NUMBER,
    validate: [
      required('The percentage is mandatory'),
      minValue(0),
      maxValue(1, 'must be 100 or less'),
    ],
    format: value => (value ? value * 100 : value),
    parse: value => (value ? value / 100 : value),
  },
];
