import {
  Company,
  CompanySubType,
  CompanyType,
  Contact,
  Contract,
  ContractIndexValue,
  CountryCode,
  CustomerDistribution,
  ExchangeRate,
  Material,
  MaterialFamily,
  MaterialIndexSource,
  MaterialIndexValue,
  MaterialReferential,
  MaterialReferentialType,
  MaterialSubFamily,
  MaterialTCO2,
  MaterialUnit,
  Organization,
  OrganizationType,
  RecyclingDomain,
  Site,
  Translation,
  TranslationTableField,
  Container,
} from './providers';
import {PROVIDER} from '../../resources';

export const ModelFactory = ({params, resource}) => {
  switch (resource) {
    case PROVIDER.COMPANIES:
      return new Company(params);
    case PROVIDER.CONTAINERS:
      return new Container(params);
    case PROVIDER.COMPANY_TYPES:
      return new CompanyType(params);
    case PROVIDER.COMPANY_SUB_TYPES:
      return new CompanySubType(params);
    case PROVIDER.CONTACTS:
      return new Contact(params);
    case PROVIDER.CONTRACTS:
      return new Contract(params);
    case PROVIDER.CONTRACT_INDEX_VALUES:
      return new ContractIndexValue(params);
    case PROVIDER.COUNTRYCODES:
      return new CountryCode(params);
    case PROVIDER.CUSTOMER_DISTRIBUTIONS:
      return new CustomerDistribution(params);
    case PROVIDER.EXCHANGE_RATES:
      return new ExchangeRate(params);
    case PROVIDER.MATERIALS:
      return new Material(params);
    case PROVIDER.MATERIAL_FAMILIES:
      return new MaterialFamily(params);
    case PROVIDER.MATERIAL_INDEX_SOURCES:
      return new MaterialIndexSource(params);
    case PROVIDER.MATERIAL_INDEX_VALUES:
      return new MaterialIndexValue(params);
    case PROVIDER.MATERIAL_REFERENTIALS:
      return new MaterialReferential(params);
    case PROVIDER.MATERIAL_REFERENTIAL_TYPES:
      return new MaterialReferentialType(params);
    case PROVIDER.MATERIAL_SUB_FAMILIES:
      return new MaterialSubFamily(params);
    case PROVIDER.MATERIAL_TCO2:
      return new MaterialTCO2(params);
    case PROVIDER.MATERIAL_UNITS:
      return new MaterialUnit(params);
    case PROVIDER.ORGANIZATIONS:
      return new Organization(params);
    case PROVIDER.ORGANIZATION_TYPES:
      return new OrganizationType(params);
    case PROVIDER.RECYCLING_DOMAINS:
      return new RecyclingDomain(params);
    case PROVIDER.SITES:
      return new Site(params);
    case PROVIDER.TRANSLATIONS:
      return new Translation(params);
    case PROVIDER.TRANSLATION_TABLE_FIELDS:
      return new TranslationTableField(params);
    default:
      return;
  }
};
