import {AbstractAPIPlatformProvider} from './abstract';
import {Document as DocumentModel} from '../model';
import {PROVIDER} from '../../../resources/bcmApi';
import {DocumentSerializer} from '../serializer';

export class Document extends AbstractAPIPlatformProvider {
  name = PROVIDER.DOCUMENTS;
  model = new DocumentModel();
  serializer = new DocumentSerializer();
}
